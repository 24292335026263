import { useQuery, useReactiveVar } from '@apollo/client';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { IAuthInput } from '../../global';
import { setIsSoundOn } from '../../gql/cache';
import type { IConfig } from '../../gql/d';
import { configGql, getProgressGql } from '../../gql/query';
import { parseQuery } from '../../utils';
import GameScreen from '../GameScreen';
import LoadScreen from '../LoadScreen';

const App: React.FC = () => {
  const { data } = useQuery<IConfig>(configGql);
  // TODO: need to understand why slot is crash without this shit
  useReactiveVar(setIsSoundOn);
  const { data: dataProgress } = useQuery<{
    progress: {
      status: number;
      wasLoaded?: boolean;
    };
  }>(getProgressGql);
  const { i18n } = useTranslation();
  const { progress } = dataProgress!;

  useEffect(() => {
    const { lng } = parseQuery<IAuthInput>();
    if (lng) {
      i18n.changeLanguage(lng);
    }
  }, [i18n]);

  return <>{data?.isAuthorized && progress?.status >= 100 ? <GameScreen /> : <LoadScreen />}</>;
};

export default App;
