import { Spine } from 'pixi-spine';
import { ITextStyle, Loader } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, Variables } from '../../config';
import { EventTypes } from '../../global.d';
import { TextField } from '../components/TextField';
import { ViewContainer } from '../components/ViewContainer';
import { eventManager } from '../config';

const STARS_AMOUNT = 8;
const FREE_SPINS_AMOUNTS = ['', '', '', '5', '10', '25', '50', '100'];

const starTextStyle: Partial<ITextStyle> = {
  fill: '#ffffff',
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 32,
  fontWeight: 'bold',
  stroke: '#DE7C12',
  strokeThickness: 8,
  lineJoin: 'round',
  whiteSpace: 'normal',
};
const starTextInactiveStyle: Partial<ITextStyle> = {
  fill: '#ffffff',
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 32,
  fontWeight: 'bold',
  stroke: '#5E3915',
  strokeThickness: 8,
  lineJoin: 'round',
  whiteSpace: 'normal',
};
const starTextActiveStyle: Partial<ITextStyle> = {
  fill: '#ffffff',
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 32,
  fontWeight: 'bold',
  stroke: '#FF5C00',
  strokeThickness: 8,
  whiteSpace: 'normal',
};
class ProgressBar extends ViewContainer {
  private backgroundAnimation: Spine;

  private progressBarStarSpines: Spine[];

  private progressBarStarTexts: TextField[];

  private starsState: number[] = [];

  constructor() {
    super();
    this.x = -125;
    this.y = -445;
    this.visible = true;
    this.progressBarStarSpines = this.initProgressBarSpines();
    this.progressBarStarTexts = this.initProgressBarTexts();
    this.backgroundAnimation = this.initBackgroundAnimation();
    this.resetStarState();
    this.initSubscriptions();
    this.addChild(this.backgroundAnimation, ...this.progressBarStarSpines);
  }

  private initSubscriptions(): void {
    eventManager.on(EventTypes.OPEN_STAR, (id: number) => {
      AudioApi.play({ type: ISongs.Progressbar_Fill, stopPrev: true });
      this.starsState[id as number] = 1;
      (this.progressBarStarTexts[id as number] as TextField).text.alpha = 1;
      (this.progressBarStarTexts[id as number] as TextField).setStyle(starTextStyle);
      const animationName = id < 3 ? 'eye_activation_1' : 'eye_activation';
      (this.progressBarStarSpines[id as number] as Spine).state.setAnimation(0, animationName, false);
      const idleAnimationName = id < 3 ? 'eye_open_idle' : 'eye_activation_idle';
      (this.progressBarStarSpines[id as number] as Spine).state.addAnimation(0, idleAnimationName, true, 0);
    });
    eventManager.on(EventTypes.WIN_STAR, () => {
      AudioApi.play({ type: ISongs.Progressbar_Win });
      const id = this.starsState.lastIndexOf(1);
      (this.progressBarStarTexts[id as number] as TextField).text.alpha = 1;
      (this.progressBarStarTexts[id as number] as TextField).setStyle(starTextActiveStyle);
      this.backgroundAnimation.state.setAnimation(0, 'pb_win_main', false);
      (this.progressBarStarSpines[id as number] as Spine).state.setAnimation(0, 'eye_win', false);
      (this.progressBarStarSpines[id as number] as Spine).state.addAnimation(0, 'eye_win_idle', true, 0);
    });
    eventManager.on(EventTypes.CLOSE_ALL_STARS, () => {
      for (let i = 0; i < STARS_AMOUNT; i++) {
        if (this.starsState[i as number] !== 0) {
          (this.progressBarStarTexts[i as number] as TextField).text.alpha = 0.5;
          (this.progressBarStarTexts[i as number] as TextField).setStyle(starTextInactiveStyle);
          (this.progressBarStarSpines[i as number] as Spine).state.setAnimation(0, 'eye_off', false);
          (this.progressBarStarSpines[i as number] as Spine).state.addAnimation(0, 'eye_close_static', true, 0);
        }
      }
      this.resetStarState();
    });
    eventManager.on(EventTypes.IMMEDIATE_CLOSE_STARS, () => {
      for (let i = 0; i < STARS_AMOUNT; i++) {
        (this.progressBarStarTexts[i as number] as TextField).text.alpha = 0.5;
        (this.progressBarStarTexts[i as number] as TextField).setStyle(starTextInactiveStyle);
        (this.progressBarStarSpines[i as number] as Spine).state.setAnimation(0, 'eye_close_static', true);
      }
      this.resetStarState();
    });
  }

  private resetStarState(): void {
    for (let i = 0; i < STARS_AMOUNT; i++) {
      this.starsState[i as number] = 0;
    }
  }

  private initProgressBarSpines(): Spine[] {
    const animations = [];
    for (let i = 0; i < STARS_AMOUNT; i++) {
      const animation = new Spine(Loader.shared.resources['progress_bar']!.spineData!);
      animation.state.setAnimation(0, 'eye_close_static', true);
      animation.scale.set(1.15);
      animation.x = 98 * i;
      animations.push(animation);
    }
    return animations;
  }

  private initProgressBarTexts(): TextField[] {
    const texts: TextField[] = [];
    for (let i = 0; i < STARS_AMOUNT; i++) {
      const textfiled = new TextField(FREE_SPINS_AMOUNTS[i as number], 100, 100, starTextInactiveStyle);
      textfiled.text.x = -2;
      textfiled.text.alpha = 0.5;
      textfiled.text.anchor.set(0.5);
      texts[i as number] = textfiled;
      (this.progressBarStarSpines[i as number] as Spine).addChild(textfiled.getText());
    }
    return texts;
  }

  private initBackgroundAnimation(): Spine {
    const animation = new Spine(Loader.shared.resources['progress_bar_win']!.spineData!);
    animation.y = 21;
    animation.x = 135;
    return animation;
  }
}

export default ProgressBar;
