import { useQuery, useReactiveVar } from '@apollo/client';
import * as PIXI from 'pixi.js';
import React, { useEffect, useRef } from 'react';

import AudioApi from '@phoenix7dev/audio-api';

import { setIsProcessToGame } from '../../gql/cache';
import type { IConfig } from '../../gql/d';
import { configGql } from '../../gql/query';
import { Logic } from '../../logic';
import AutoPlaySettingsMenu from '../AutoPlaySettings/AutoPlaySettingsPopup';
import BetSettingsPopup from '../BetSettings/BetSettingsPopup';
import EventListener from '../EventListener';
import HistoryPopup from '../History/HistoryPopup';
import InfoPopup from '../Info/InfoPopup';
import Spin from '../SpinButton';

import styles from './gameScreen.module.scss';

window.PIXI = PIXI;

const GameScreen: React.FC = () => {
  const { data: clientData } = useQuery<IConfig>(configGql);
  const { isSoundOn } = clientData!;
  const processToGame = useReactiveVar(setIsProcessToGame);
  const pixiContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    AudioApi.mute(isSoundOn);
  }, [isSoundOn]);

  useEffect(() => {
    pixiContainerRef.current?.appendChild(Logic.the.application.view);
    Logic.the.init();
  }, []);

  return (
    <>
      <div className={styles['canvas']} ref={pixiContainerRef} />
      {processToGame && (
        <>
          <EventListener />
          <AutoPlaySettingsMenu />
          <InfoPopup />
          <HistoryPopup />
          <BetSettingsPopup />
          <Spin />
        </>
      )}
    </>
  );
};

export default GameScreen;
