import { EventTypes } from '../../global.d';
import { calcBottomContainerHeight, calcPercentage } from '../../utils';
import { ViewContainer } from '../components/ViewContainer';
import { eventManager } from '../config';

class SafeArea extends ViewContainer {
  constructor() {
    super();
    this.zIndex = 1;
  }

  private calculateSize(width: number, height: number): number[] {
    let newWidth = 0;
    let newHeight = 0;

    const bottomContainerHeight = calcBottomContainerHeight(width, height);
    const bottomSpace = bottomContainerHeight;
    const isLandscape = width >= height;

    const gap = calcPercentage(width, 35);
    const gapWidth = width - gap;

    if (isLandscape) {
      const h = gapWidth < height ? gapWidth : height;
      newWidth = ((h - bottomSpace) * 4) / 2.9;
      newHeight = h - bottomSpace;

      return [newWidth, newHeight, bottomSpace];
    }

    const nWidth = width;
    newWidth = nWidth;
    newHeight = nWidth * 0.75 - bottomSpace;

    return [newWidth, newHeight, bottomSpace];
  }

  protected override resize(width: number, height: number): void {
    const [newWidth, newHeight, bottomSpace] = this.calculateSize(width, height);
    const x = (width - (newWidth as number)) / 2;
    const y = (height - (bottomSpace as number)) / 2;
    this.position.set(x, y);
    eventManager.emit(EventTypes.RESIZE_GAME_CONTAINER, newWidth, newHeight, width, height);
  }
}
export default SafeArea;
