import type { ObservablePoint } from 'pixi.js';

import type Animation from './animation';

export enum TweenProperties {
  POSITION = 'position',
  X = 'x',
  Y = 'y',
  WIN_VALUE = 'winValue',
  ROTATION = 'rotation',
  TEXT = 'text',
  ALPHA = 'alpha',
}

export interface ITweenObject {
  [TweenProperties.POSITION]?: number | ObservablePoint;
  [TweenProperties.X]?: number;
  [TweenProperties.Y]?: number;
  [TweenProperties.WIN_VALUE]?: number;
  [TweenProperties.ROTATION]?: number;
  [TweenProperties.ALPHA]?: number;
  [TweenProperties.TEXT]?: string | number;
}

export interface ITween extends IAnimation {
  object: ITweenObject;
  property: TweenProperties;
  propertyBeginValue: number;
  target: number;
}

export interface IAnimation {
  startTime?: number;
  change?: (() => void)[];
  complete?: { callback: () => void; priority: CallbackPriority }[];
  starts?: (() => void)[];
  skips?: (() => void)[];
  easing?: (n: number) => number;
  duration?: number;
  delay?: number;
  isLoop?: boolean;
  ended?: boolean;
  update?: ((n: number) => void) | null;
}

export enum CallbackPriority {
  CHAIN,
  REGULAR,
  HIGH,
  IMMEDIATE,
}

export interface ISpinAnimation extends IAnimation {
  disappearingAnimation: Animation;
  waitingAnimation: Animation;
  appearingAnimation?: Animation;
}
