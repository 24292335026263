import type { Group } from '@pixi/layers';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, SlotId } from '../../config';
import { GameMode } from '../../global.d';
import { setCurrentIsTurboSpin } from '../../gql/cache';
import { Logic } from '../../logic';
import { cascadeEase } from '../../utils';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { CascadeAnimation } from '../animations/cascade/cascadeAnimation';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { ViewContainer } from '../components/ViewContainer';
import {
  BASE_DISAPPEARING_DURATION,
  REEL_WIDTH,
  ReelState,
  SLOTS_PER_REEL_AMOUNT,
  SLOT_HEIGHT,
  SLOT_WIDTH,
  TURBO_DISAPPEARING_DURATION,
} from '../config';
import type { Icon } from '../d';

import type { IReel } from './d';
import Slot from './slot';

class Reel implements IReel {
  public id: number;

  public state: ReelState;

  public data: SlotId[];

  public container: ViewContainer;

  public position = 0;

  public cascadeAnimation: CascadeAnimation | null = null;

  public slots: Slot[] = [];

  public isPlaySoundOnStop = false;

  public size: number;

  constructor(id: number, data: SlotId[], startPosition: number, cascades: Icon[], slotGroup: Group) {
    this.id = id;
    this.data = data;
    this.size = data.length;
    this.state = ReelState.IDLE;
    this.container = new ViewContainer();
    this.container.sortableChildren = true;
    this.container.width = SLOT_WIDTH;
    this.container.x = id * REEL_WIDTH + (REEL_WIDTH - SLOT_WIDTH) / 2;
    this.container.y = 0;
    this.createSlots(
      cascades.map((cascade) => cascade.id),
      slotGroup,
    );
    this.position = this.size - startPosition;
  }

  public clean(): void {
    this.container.removeChildren();
    this.slots = [];
  }

  public init(data: SlotId[]): void {
    this.data = data;
    this.size = data.length;
  }

  public createSlots(slots: SlotId[], slotGroup: Group): void {
    this.slots = [];
    this.container.removeChildren();
    for (let i = 0; i < slots.length; i++) {
      const slot = new Slot(slots.length - i - 1, slots[i as number] as SlotId);
      this.slots.push(slot);
      slot.parentGroup = slotGroup;
      this.container.addChild(slot);
    }
  }

  public createCascadeAnimation(): CascadeAnimation {
    const isTurboSpin =
      setCurrentIsTurboSpin() &&
      (Logic.the.controller.gameMode === GameMode.BASE_GAME ||
        Logic.the.controller.gameMode === GameMode.FREE_ROUND_BONUS);
    const disappearingAnimation = new AnimationChain();
    const disappearGroup = new AnimationGroup();
    for (let i = 0; i < this.slots.length; i++) {
      const slot = this.slots[this.slots.length - i - 1];
      const delay = i * 10;
      const target = (2 * SLOTS_PER_REEL_AMOUNT - i) * SLOT_HEIGHT;
      const propertyBeginValue = (SLOTS_PER_REEL_AMOUNT - i - 0.5) * SLOT_HEIGHT;
      const disappearing = new Tween({
        object: slot as Slot,
        property: TweenProperties.Y,
        propertyBeginValue,
        target,
        delay,
        duration: isTurboSpin ? TURBO_DISAPPEARING_DURATION : BASE_DISAPPEARING_DURATION,
        easing: cascadeEase,
      });
      disappearGroup.addAnimation(disappearing);
    }
    disappearingAnimation.appendAnimation(disappearGroup);
    disappearingAnimation.addOnStart(() => {
      this.changeState(ReelState.DISAPPEARING);
    });

    const waiting = Tween.createDelayAnimation(12000);
    waiting.addOnStart(() => {
      this.changeState(ReelState.WAITING);
    });
    this.cascadeAnimation = new CascadeAnimation({
      disappearingAnimation,
      waitingAnimation: waiting,
    });
    return this.cascadeAnimation;
  }

  private onReelEnding(_previousState: ReelState, _newState: ReelState): void {}

  private onReelStop(): void {
    AudioApi.play({ type: ISongs.SFX_UI_SpinStop, stopPrev: true });
  }

  private onReelIdle(previousState: ReelState, _newState: ReelState): void {
    if (previousState === ReelState.APPEARING) {
      this.onReelStop();
    }
  }

  private onReelRolling(_previousState: ReelState, _newState: ReelState): void {}

  private onReelStarting(_previousState: ReelState, _newState: ReelState): void {}

  public changeState(newState: ReelState): void {
    const previousState = this.state;
    this.state = newState;
    if (newState === ReelState.IDLE) {
      this.onReelIdle(previousState, ReelState.IDLE);
    }
    if (newState === ReelState.DISAPPEARING) {
      this.onReelRolling(previousState, ReelState.DISAPPEARING);
    }
    if (newState === ReelState.WAITING) {
      this.onReelStarting(previousState, ReelState.WAITING);
    }
    if (newState === ReelState.APPEARING) {
      this.onReelEnding(previousState, ReelState.APPEARING);
    }
  }
}

export default Reel;
