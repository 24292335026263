import i18n from 'i18next';
import { Application, Container } from 'pixi.js';

import { Cascade, EventTypes, GameMode, ReelSet, UserBonus } from '../global.d';
import {
  setBetResult,
  setBrokenGame,
  setIsRevokeThrowingError,
  setIsTimeoutErrorMessage,
  setStressful,
  setUserLastBetResult,
} from '../gql/cache';
import { Logic } from '../logic';
import { getBetResult, getCascadeColumns, isBuyFeatureEnabled } from '../utils';

import AnimationChain from './animations/animationChain';
import AnimationGroup from './animations/animationGroup';
import type { CascadeAnimation } from './animations/cascade/cascadeAnimation';
import Tween from './animations/tween';
import Backdrop from './backdrop/backdrop';
import Background from './background/background';
import { BigWinContainer } from './bigWinPresentation/bigWinContainer';
import BottomContainer from './bottomContainer/bottomContainer';
import BuyFeatureBtn from './buyFeature/buyFeatureBtn';
import { PopupTypes, REELS_AMOUNT, eventManager } from './config';
import AutoplayBtn from './controlButtons/autoplayBtn';
import BetBtn from './controlButtons/betBtn';
import InfoBtn from './controlButtons/infoBtn';
import MenuBtn from './controlButtons/menuBtn';
import SpinBtn from './controlButtons/spinBtn';
import TurboSpinBtn from './controlButtons/turboSpinBtn';
import type { ISlotData } from './d';
import FadeArea from './fadeArea/fadeArea';
import GameView from './gameView/gameView';
import MiniPayTableContainer from './miniPayTable/miniPayTableContainer';
import PhoenixAnticipation from './phoenixAnticipation/phoenixAnticipation';
import { PopupController } from './popups/PopupController';
import BuyFeaturePopup from './popups/buyFeaturePopup';
import BuyFeaturePopupConfirm from './popups/buyFeaturePopupConfirm';
import { FreeRoundsPopup } from './popups/freeRoundsPopup';
import { FreeRoundsEndPopup } from './popups/freeRoundsPopupEnd';
import { FreeSpinsEndPopup } from './popups/freeSpinsEndPopup';
import { FreeSpinsPopup } from './popups/freeSpinsPopup';
import { RageModeInfoPopup } from './popups/rageModeInfoPopup';
import { RageModePopup } from './popups/rageModePopup';
import { RandomWilds } from './randomWildsContainer/randomWilds';
import ReelsBackgroundContainer from './reels/background/reelsBackground';
import type Reel from './reels/reel';
import ReelsContainer from './reels/reelsContainer';
import type Slot from './reels/slot';
import SafeArea from './safeArea/safeArea';
import WinCountUpMessage from './winAnimations/winCountUpMessage';
import WinSlotsContainer from './winAnimations/winSlotsContainer';

class SlotMachine {
  private application: Application;

  public isStopped = false;

  private static slotMachine: SlotMachine;

  public static initSlotMachine = (slotData: ISlotData): void => {
    SlotMachine.slotMachine = new SlotMachine(Logic.the.application, slotData);
  };

  public static the(): SlotMachine {
    return SlotMachine.slotMachine;
  }

  public gameView: GameView;

  public reelsContainer: ReelsContainer;

  public miniPayTableContainer: MiniPayTableContainer;

  public menuBtn: MenuBtn;

  public turboSpinBtn: TurboSpinBtn;

  public spinBtn: SpinBtn;

  public betBtn: BetBtn;

  public autoplayBtn: AutoplayBtn;

  public infoBtn: InfoBtn;

  private constructor(application: Application, slotData: ISlotData) {
    this.application = application;
    this.initEventListeners();
    this.application.stage.sortableChildren = true;
    const startPosition = setUserLastBetResult().id
      ? setUserLastBetResult().result.reelPositions
      : slotData.settings.startPosition;
    const reelSet = setUserLastBetResult().id
      ? slotData.reels.find((reelSet) => reelSet.id === setUserLastBetResult().reelSetId)!
      : slotData.reels[0];
    this.reelsContainer = new ReelsContainer((reelSet as ReelSet).layout, startPosition);
    this.miniPayTableContainer = new MiniPayTableContainer(slotData.icons, this.getSlotById.bind(this));
    this.miniPayTableContainer.setSpinResult(this.reelsContainer.getCurrentSpinResult());
    this.gameView = this.initGameView(slotData);
    this.menuBtn = new MenuBtn();
    this.turboSpinBtn = new TurboSpinBtn();
    this.spinBtn = new SpinBtn();
    this.betBtn = new BetBtn();
    this.autoplayBtn = new AutoplayBtn();
    this.infoBtn = new InfoBtn();
    this.initPixiLayers();
    this.application.stage.addChild(
      this.menuBtn,
      this.turboSpinBtn,
      this.spinBtn,
      this.betBtn,
      this.autoplayBtn,
      this.infoBtn,
    );
  }

  private initPopupContainer(): Container {
    const container = new Container();
    container.name = 'buyFeaturePopup';
    const buyFeaturePopup = new BuyFeaturePopup();
    const buyFeaturePopupConfirm = new BuyFeaturePopupConfirm();
    PopupController.the.registerPopup(PopupTypes.BUY_FEATURE, buyFeaturePopup);
    PopupController.the.registerPopup(PopupTypes.BUY_FEATURE_CONFIRMATION, buyFeaturePopupConfirm);
    container.addChild(buyFeaturePopup, buyFeaturePopupConfirm);
    return container;
  }

  private initPixiLayers(): void {
    const freeSpinsPopup = new FreeSpinsPopup();
    const freeSpinsEndPopup = new FreeSpinsEndPopup();
    const freeRoundsPopup = new FreeRoundsPopup();
    const freeRoundsEndPopup = new FreeRoundsEndPopup();
    const rageModePopup = new RageModePopup();
    const rageModeInfoPopup = new RageModeInfoPopup();
    const phoenixAnticipation = new PhoenixAnticipation();
    PopupController.the.registerPopup(PopupTypes.FREE_SPINS, freeSpinsPopup);
    PopupController.the.registerPopup(PopupTypes.FREE_SPINS_END, freeSpinsEndPopup);
    PopupController.the.registerPopup(PopupTypes.FREE_ROUNDS, freeRoundsPopup);
    PopupController.the.registerPopup(PopupTypes.FREE_ROUNDS_END, freeRoundsEndPopup);
    PopupController.the.registerPopup(PopupTypes.RAGE_MODE, rageModePopup);
    PopupController.the.registerPopup(PopupTypes.RAGE_MODE_INFO, rageModeInfoPopup);
    this.application.stage.addChild(
      new Background(),
      new Backdrop(EventTypes.OPEN_POPUP_BG, EventTypes.CLOSE_POPUP_BG),
      this.initSafeArea(),
      freeSpinsEndPopup,
      freeSpinsPopup,
      rageModePopup,
      rageModeInfoPopup,
      freeRoundsPopup,
      freeRoundsEndPopup,
      new BottomContainer(),
      new RandomWilds(),
      new BigWinContainer(),
      new FadeArea(),
      phoenixAnticipation,
    );
  }

  private initSafeArea(): SafeArea {
    const safeArea = new SafeArea();
    safeArea.addChild(this.gameView);
    return safeArea;
  }

  private initGameView(slotData: ISlotData): GameView {
    const gameView = new GameView({
      winSlotsContainer: new WinSlotsContainer(),
      reelsBackgroundContainer: new ReelsBackgroundContainer(),
      reelsContainer: this.reelsContainer,
      winCountUpMessage: new WinCountUpMessage(),
      miniPayTableContainer: this.miniPayTableContainer,
    });
    gameView.interactive = true;
    gameView.on('mousedown', () => eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION));
    gameView.on('touchstart', () => eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION));

    if (isBuyFeatureEnabled(slotData.clientSettings.features)) {
      gameView.addChild(new BuyFeatureBtn());
    }
    gameView.addChild(this.initPopupContainer());

    return gameView;
  }

  public onBrokenGame(bonus: UserBonus): void {
    eventManager.emit(EventTypes.BROKEN_GAME, bonus);
    eventManager.emit(EventTypes.GAME_READY);
  }

  private initEventListeners(): void {
    this.application.renderer.once(EventTypes.POST_RENDER, () => {
      if (!setBrokenGame()) eventManager.emit(EventTypes.GAME_READY);
    });
    eventManager.addListener(EventTypes.SET_CURRENT_RESULT_MINI_PAYTABLE, this.setCurrentResultMiniPayTable.bind(this));
    eventManager.addListener(EventTypes.START_CASCADE_FEATURE, this.startCascadeFeature.bind(this));
    eventManager.addListener(EventTypes.NEXT_CASCADE, this.nextCascade.bind(this));
    eventManager.addListener(EventTypes.THROW_ERROR, SlotMachine.handleError);
  }

  public throwTimeoutError(): void {
    eventManager.emit(EventTypes.THROW_ERROR);
  }

  private static handleError(): void {
    if (!setIsRevokeThrowingError()) {
      setIsRevokeThrowingError(true);
      setIsTimeoutErrorMessage(true);
      setStressful({
        show: true,
        type: 'network',
        message: i18n.t('errors.UNKNOWN.UNKNOWN'),
      });
    }
  }

  private removeErrorHandler(): void {
    (this.reelsContainer.reels[REELS_AMOUNT - 1] as Reel).cascadeAnimation
      ?.getWaiting()
      .removeOnComplete(this.throwTimeoutError);
  }

  public spinSpinAnimation(): void {
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    const spinAnimation = this.getSpinAnimation();
    eventManager.emit(EventTypes.START_SPIN_ANIMATION);
    spinAnimation.start();
  }

  private getSpinAnimation(): AnimationGroup {
    const animationGroup = new AnimationGroup();
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const reel = this.reelsContainer.reels[i as number] as Reel;
      const cascadeAnimation: CascadeAnimation = reel.createCascadeAnimation();
      if (i === REELS_AMOUNT - 1) {
        cascadeAnimation.getWaiting().addOnChange(() => {
          if (setBetResult() && !Logic.the.isReadyForStop) {
            Logic.the.isReadyForStop = true;
            this.removeErrorHandler();
            const betResult = getBetResult(setBetResult());
            eventManager.emit(
              EventTypes.SETUP_REEL_POSITIONS,
              getCascadeColumns({
                reelPositions: betResult.bet.result.reelPositions,
                layout: betResult.bet.reelSet.layout,
                cascades: betResult.bet.data.features.cascade,
              }),
              Logic.the.isStoppedBeforeResult,
            );
          }
        });
        cascadeAnimation.getWaiting().addOnComplete(this.throwTimeoutError);
      }
      (this.reelsContainer.reels[i as number] as Reel).isPlaySoundOnStop = true;
      animationGroup.addAnimation(cascadeAnimation);
    }

    return animationGroup;
  }

  private startCascadeFeature(): void {
    const betResult = getBetResult(setBetResult());
    const [cascade] = betResult.bet.data.features.cascade;
    if ((cascade as Cascade).isRandomWilds) {
      eventManager.emit(EventTypes.START_RANDOM_WILDS_ANIMATION, cascade, 1);
      return;
    }
    eventManager.emit(EventTypes.START_WIN_ANIMATION, betResult.bet.result.spinResult, cascade, 0);
    const { winAmounts } = cascade as Cascade;
    eventManager.emit(
      EventTypes.START_COUNT_UP,
      0,
      winAmounts.reduce((x, y) => x + y, 0),
      0,
    );
  }

  private nextCascade(id: number): void {
    const betResult = getBetResult(setBetResult());
    const { cascade } = betResult.bet.data.features;
    const chain = new AnimationChain({});
    if (Logic.the.controller.gameMode === GameMode.FREE_SPINS) {
      chain.appendAnimation(Tween.createDelayAnimation(1000));
    }
    const resetAnimation = this.reelsContainer.createResetReelsAnimation((cascade[id - 1] as Cascade).winPositions);
    if (!(cascade[id - 1] as Cascade).isRandomWilds) {
      const starId = cascade.filter((elem, index) => index < id && !elem.isRandomWilds).length - 1;
      if (
        Logic.the.controller.gameMode === GameMode.BASE_GAME ||
        (Logic.the.controller.gameMode === GameMode.FREE_ROUND_BONUS && starId < 8)
      ) {
        eventManager.emit(EventTypes.OPEN_STAR, starId);
      }
      if (Logic.the.controller.gameMode === GameMode.FREE_SPINS) {
        eventManager.emit(EventTypes.OPEN_MULTIPLIER_STAR, starId);
      }
    }
    resetAnimation.addOnComplete(() => {
      const spinResult = this.reelsContainer.getCurrentSpinResult();
      if (id >= cascade.length) {
        eventManager.emit(EventTypes.END_CASCADE_FEATURE);
        return;
      }
      if ((cascade[id as number] as Cascade).isRandomWilds) {
        eventManager.emit(EventTypes.START_RANDOM_WILDS_ANIMATION, cascade[id as number], id + 1);
        return;
      }
      const { winAmounts } = cascade[id as number] as Cascade;
      const prevWin = cascade.reduce((sum, current, index) => {
        return index >= id ? sum : sum + current.winAmounts.reduce((x, y) => x + y, 0);
      }, 0);
      eventManager.emit(EventTypes.START_WIN_ANIMATION, spinResult, cascade[id as number], id);
      eventManager.emit(EventTypes.START_COUNT_UP, prevWin, prevWin + winAmounts.reduce((x, y) => x + y, 0), id);
    });
    chain.appendAnimation(resetAnimation);
    chain.start();
  }

  public getSlotAt(x: number, y: number): Slot | null {
    return this.reelsContainer.reels[x as number]!.slots[y as number]!;
  }

  public getSlotById(id: number): Slot | null {
    return this.getSlotAt(id % REELS_AMOUNT, Math.floor(id / REELS_AMOUNT));
  }

  public setCurrentResultMiniPayTable(): void {
    this.miniPayTableContainer.setSpinResult(this.reelsContainer.getCurrentSpinResult());
  }
}

export default SlotMachine;
