export enum ResourceTypes {
  backdrop = 'backdrop',
  buyFeaturePopupBackplate = 'buyFeaturePopupBackplate',
  buyFeaturePopupConfirm = 'buyFeaturePopupConfirm',
  buyFeaturePopup = 'buyFeaturePopup',
  buyFeatureBtnDisable = 'buyFeatureBtnDisable',
  buyFeatureBtnHover = 'buyFeatureBtnHover',
  buyFeatureBtnPressed = 'buyFeatureBtnPressed',
  buyFeatureBtn = 'buyFeatureBtn',
  buyFeatureCancelBtnDisable = 'buyFeatureCancelBtnDisable',
  buyFeatureCancelBtnHover = 'buyFeatureCancelBtnHover',
  buyFeatureCancelBtnPressed = 'buyFeatureCancelBtnPressed',
  buyFeatureCancelBtn = 'buyFeatureCancelBtn',
  buyFeatureConfirmBtnDefault = 'buyFeatureConfirmBtnDefault',
  buyFeatureConfirmBtnDisabled = 'buyFeatureConfirmBtnDisabled',
  buyFeatureConfirmBtnHover = 'buyFeatureConfirmBtnHover',
  buyFeatureConfirmBtnPressed = 'buyFeatureConfirmBtnPressed',
  buyFeatureFreeSpinsDisabled = 'buyFeatureFreeSpinsDisabled',
  buyFeatureFreeSpinsHover = 'buyFeatureFreeSpinsHover',
  buyFeatureFreeSpinsPressed = 'buyFeatureFreeSpinsPressed',
  buyFeatureFreeSpins = 'buyFeatureFreeSpins',
  buyFeaturePartyModeDisabled = 'buyFeaturePartyModeDisabled',
  buyFeaturePartyModeHover = 'buyFeaturePartyModeHover',
  buyFeaturePartyModePressed = 'buyFeaturePartyModePressed',
  buyFeaturePartyMode = 'buyFeaturePartyMode',
  buyFeatureMinusBtnDisable = 'buyFeatureMinusBtnDisable',
  buyFeatureMinusBtnHover = 'buyFeatureMinusBtnHover',
  buyFeatureMinusBtnPressed = 'buyFeatureMinusBtnPressed',
  buyFeatureMinusBtn = 'buyFeatureMinusBtn',
  buyFeaturePlusBtnDisable = 'buyFeaturePlusBtnDisable',
  buyFeaturePlusBtnHover = 'buyFeaturePlusBtnHover',
  buyFeaturePlusBtnPressed = 'buyFeaturePlusBtnPressed',
  buyFeaturePlusBtn = 'buyFeaturePlusBtn',
  frameFreeSpins = 'frameFreeSpins',
  framePartyMode = 'framePartyMode',
  frame = 'frame',
  freeSpinsCounter = 'freeSpinsCounter',
  introBg = 'introBg',
  introReel1 = 'introReel1',
  introReel2 = 'introReel2',
  introReel3 = 'introReel3',
  labelPartyMode = 'labelPartyMode',
  reelBackgroundFreeSpins = 'reelBackgroundFreeSpins',
  reelBackgroundPartyMode = 'reelBackgroundPartyMode',
  reelBackground = 'reelBackground',
  slotBgFreeSpins = 'slotBgFreeSpins',
  slotBgPartyMode = 'slotBgPartyMode',
  slotBg = 'slotBg',
  a = 'a',
  b = 'b',
  c = 'c',
  d = 'd',
  e = 'e',
  f = 'f',
  g = 'g',
  h = 'h',
  i = 'i',
  j = 'j',
  wL = 'wL',
  infoPaylines = 'infoPaylines',
  infoPaylines2 = 'infoPaylines2',
  logo = 'logo',
}
