import { TextStyle } from 'pixi.js';

import { Variables } from '../../config';

export const modeButtonTextStyle = new TextStyle({
  align: 'center',
  fill: '#FFD04D',
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 27,
  lineHeight: 29,
  fontWeight: 'bold',
  miterLimit: 5,
  padding: 5,
  whiteSpace: 'normal',
  wordWrapWidth: 140,
});

export const titleBaseTextStyle = new TextStyle({
  align: 'center',
  fill: '#ffffff',
  fillGradientType: 1,
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 36,
  lineHeight: 42,
  fontWeight: 'bold',
  miterLimit: 5,
  padding: 5,
  stroke: '#DD1A7A',
  strokeThickness: 10,
  lineJoin: 'round',
  whiteSpace: 'normal',
  wordWrapWidth: 140,
  trim: true,
});

export const betValueStyle = new TextStyle({
  align: 'center',
  fill: 'white',
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 26,
  fontWeight: 'bold',
  miterLimit: 5,
  padding: 5,
  whiteSpace: 'normal',
  wordWrapWidth: 140,
});

export const totalCostTextStyle = new TextStyle({
  align: 'center',
  fill: '#FFF12C',
  fillGradientType: 1,
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 26,
  fontWeight: 'bold',
  miterLimit: 5,
  padding: 5,
  stroke: '#DD1A79',
  lineJoin: 'round',
  strokeThickness: 6,
  whiteSpace: 'normal',
  wordWrapWidth: 140,
});

export const totalCostValueTextStyle = new TextStyle({
  align: 'center',
  fill: '#ffffff',
  fillGradientType: 1,
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 38,
  fontWeight: 'bold',
  miterLimit: 5,
  padding: 5,
  stroke: '#DD1A7A',
  strokeThickness: 10,
  lineJoin: 'round',
  whiteSpace: 'normal',
  wordWrapWidth: 140,
  trim: true,
});
