import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, GameMode } from '../../global.d';
import {
  setBetResult,
  setBottomContainerTotalWin,
  setBrokenGame,
  setCurrentBonus,
  setFreeSpinsTotalWin,
  setIsSlotBusy,
  setLastRegularWinAmount,
} from '../../gql/cache';
import { PopupTypes, eventManager } from '../../slotMachine/config';
import { PopupController } from '../../slotMachine/popups/PopupController';
import { getBetResult } from '../../utils';
import { States } from '../config';
import { Logic } from '../index';

import { FreeSpinController } from './FreeSpinController';

export class RageModeController extends FreeSpinController {
  public override gameMode = GameMode.RAGE_MODE;

  public static override the = new RageModeController();

  public override enterWinPresentationState(_prevState: States): void {
    eventManager.once(EventTypes.END_CASCADE_FEATURE, () => {
      eventManager.emit(EventTypes.START_MULTIPLIER_ANIMATION, 'x10_rage_mode');
      setTimeout(() => Logic.the.changeState(States.AFTER_WIN), 1000);
    });

    eventManager.emit(EventTypes.START_CASCADE_FEATURE, getBetResult(setBetResult()).bet.data.features.cascade);
  }

  public override enterJingleState(_prevState: States): void {
    eventManager.emit(EventTypes.UPDATE_USER_BALANCE, getBetResult(setBetResult()).balance.settled);
    if (setBottomContainerTotalWin() > 0) {
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setBottomContainerTotalWin());
    }
    Logic.the.changeState(States.IDLE);
  }

  public override enterController(_prevGameMode: GameMode): void {
    eventManager.emit(EventTypes.DISABLE_PAYTABLE);
    if (setBrokenGame()) AudioApi.play({ type: ISongs.BGM_RM_Loop });
    setIsSlotBusy(false);
    eventManager.emit(
      EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE,
      setCurrentBonus().currentRound,
      setCurrentBonus().rounds,
    );
    eventManager.emit(EventTypes.HIDE_WIN_LABEL);
    if (setBottomContainerTotalWin() > 0) {
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setBottomContainerTotalWin());
    }
    if (setBrokenGame()) {
      Logic.the.changeState(States.IDLE);
      return;
    }
    eventManager.once(EventTypes.START_RAGE_MODE, () => {
      PopupController.the.closeCurrentPopup();
      Logic.the.changeState(States.IDLE);
    });
    PopupController.the.openPopup(PopupTypes.RAGE_MODE);
  }

  public override exitController(_nextGameMode: GameMode): void {
    setLastRegularWinAmount(setFreeSpinsTotalWin());
    AudioApi.stop({ type: ISongs.BGM_RM_Loop });
    if (setBrokenGame()) setBrokenGame(false);
    setCurrentBonus({ ...setCurrentBonus(), isActive: false });
    eventManager.emit(EventTypes.HIDE_COUNT_UP);
  }
}
