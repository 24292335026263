import { isMobile } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import type { TPlay } from '@phoenix7dev/audio-api/dist/d';

import { ISongs, SlotId } from '../config';
import { FeatureState, GameMode, ISettledBet, baseReelSetIds, buyFeatureBonusesId } from '../global.d';
import { setBetAmount, setIsDuringBigWinLoop } from '../gql/cache';
import Tween from '../slotMachine/animations/tween';
import {
  BASE_WIN_AMOUNT_LIMIT,
  BIG_WIN_AMOUNT_LIMIT,
  DOUBLE_WIN_AMOUNT_LIMIT,
  GREAT_WIN_AMOUNT_LIMIT,
  MEGA_WIN_AMOUNT_LIMIT,
  WinStages,
} from '../slotMachine/config';

import { normalizeCoins } from './utils';

declare namespace Helper {
  export type RestArguments = unknown[];
  export type Callback<T> = (...args: RestArguments) => T;
  export interface WrapArguments<T> {
    (fn: Callback<T>, ...partOne: RestArguments): Callback<T>;
  }
}

export const getWsUtl = (url: string): string => {
  const { protocol, host } = window.location;
  return `${protocol.replace('http', 'ws')}//${host}${url}`;
};

export const parseQuery = <T>(): T => {
  const { search } = window.location;
  const str = search
    .slice(1)
    .split('&')
    .map((i) => i.split('='));

  const param = str.reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key as string]: value,
    };
  }, {});
  return param as T;
};

export const wrap =
  (fn: CallableFunction, ...partOne: Helper.RestArguments) =>
  (...partTwo: Helper.RestArguments): unknown => {
    const args: Helper.RestArguments = [...partOne, ...partTwo];
    if (args.length) {
      return fn(...args);
    }
    return fn();
  };

export const isMobileDevice = (): boolean => {
  const regex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|WPDesktop/;
  return (
    regex.test(window.navigator.userAgent) ||
    (window.navigator.platform === 'MacIntel' &&
      typeof (window.navigator as unknown as { standalone: unknown }).standalone !== 'undefined')
  );
};

export const getBetResult = (betResult: ISettledBet | null): ISettledBet => {
  if (betResult === null) throw new Error('Invalid bet result');
  return betResult;
};

export const getBonusIdByFeature = (featureState: FeatureState): string => {
  return buyFeatureBonusesId[featureState as FeatureState] as string;
};

export const isRegularMode = (mode: GameMode): boolean => {
  return mode === GameMode.BASE_GAME || mode === GameMode.FREE_ROUND_BONUS;
};

export const isFreeSpinMode = (mode: GameMode): boolean => {
  return mode === GameMode.FREE_SPINS || mode === GameMode.RAGE_MODE;
};

export const isBuyFeatureMode = (mode: GameMode): boolean => {
  return mode === GameMode.BUY_FEATURE;
};
export const getWinStage = (winAmount: number): WinStages => {
  const betAmount = normalizeCoins(setBetAmount());
  const multiplier = normalizeCoins(winAmount) / betAmount;

  if (multiplier < DOUBLE_WIN_AMOUNT_LIMIT) {
    return WinStages.None;
  }
  if (multiplier >= DOUBLE_WIN_AMOUNT_LIMIT && multiplier < BASE_WIN_AMOUNT_LIMIT) {
    return WinStages.BaseWin;
  }
  if (multiplier >= BASE_WIN_AMOUNT_LIMIT && multiplier < BIG_WIN_AMOUNT_LIMIT) {
    return WinStages.BigWin;
  }
  if (multiplier >= BIG_WIN_AMOUNT_LIMIT && multiplier < MEGA_WIN_AMOUNT_LIMIT) return WinStages.MegaWin;
  if (multiplier >= MEGA_WIN_AMOUNT_LIMIT && multiplier < GREAT_WIN_AMOUNT_LIMIT) return WinStages.GreatWin;
  return WinStages.EpicWin;
};

export const isBaseReelSet = (reelSetId: string): boolean => {
  return baseReelSetIds.includes(reelSetId);
};

export const nextTick = (callback: () => void): void => {
  setImmediate(callback);
};
export const countCoins = (bet: {
  totalAmount?: number;
  coinAmount?: number;
  coinValue?: number;
  lines?: number;
}): number => {
  if (bet.totalAmount) {
    return (bet.totalAmount * (bet.coinValue || 100)) / 100;
  }
  return ((bet.coinAmount || 0) * (bet.coinValue || 100) * 50) / 100;
};

export const normalizePosition = (length: number, position: number): number => {
  return (length + position) % length;
};

export const saveReelPosition = (reelPositions: number[]): void => {
  const positions = reelPositions.toString();
  sessionStorage.setItem('positions', btoa(positions));
};

export const calcPercentage = (initialValue: number, percent: number): number => {
  return (initialValue / 100) * percent;
};

export const canPressSpin = ({
  gameMode,
  isFreeSpinsWin,
  isSpinInProgress,
  isSlotBusy,
  isSlotStopped,
  isPopupOpened,
  isAutoPlay,
}: {
  gameMode: GameMode;
  isFreeSpinsWin: boolean;
  bonusCurrentRound?: number;
  isSpinInProgress: boolean;
  isSlotBusy: boolean;
  isSlotStopped: boolean;
  isPopupOpened: boolean;
  isAutoPlay: boolean;
}): boolean => {
  if (
    (gameMode === GameMode.BASE_GAME || gameMode === GameMode.FREE_ROUND_BONUS || isBuyFeatureMode(gameMode)) &&
    isFreeSpinsWin
  ) {
    return false;
  }

  if (isFreeSpinMode(gameMode) && !isSlotBusy) {
    return false;
  }

  if (isSpinInProgress && isSlotStopped) {
    return false;
  }

  if (isPopupOpened) {
    return false;
  }
  if (isAutoPlay) {
    return false;
  }

  return true;
};

export const delayedAction = (delay: number, completeCallback: () => void, startCallback?: () => void): void => {
  const delayAnim = Tween.createDelayAnimation(delay);
  if (startCallback) {
    delayAnim.addOnStart(startCallback);
  }
  delayAnim.addOnComplete(completeCallback);
  delayAnim.start();
};

export const isRageModeWin = (result: ISettledBet): boolean => {
  return result.bet.data.features.multiplier === 10;
};

export const getBGMSoundByGameMode = (mode: GameMode): ISongs => {
  switch (mode) {
    case GameMode.BASE_GAME:
      return ISongs.BGM_BG_Melo_Loop;
    case GameMode.FREE_SPINS:
      return ISongs.BGM_FS_Loop;
    case GameMode.RAGE_MODE:
      return ISongs.BGM_RM_Loop;
    default:
      return ISongs.BGM_BG_Base_Loop;
  }
};

export const handleChangeRestriction = (mode: GameMode): void => {
  AudioApi.unSuspend();
  AudioApi.processRestriction({
    restricted: false,
  });
  const list: TPlay[] = [];
  if (setIsDuringBigWinLoop()) {
    list.push({ type: ISongs.BigWin_Loop });
  }
  switch (mode) {
    case GameMode.BASE_GAME:
      list.push({ type: ISongs.BGM_BG_Base_Loop });
      break;
    case GameMode.FREE_SPINS:
      list.push({ type: ISongs.BGM_FS_Loop, stopImmediately: [ISongs.BGM_BG_Base_Loop] });
      break;
    case GameMode.RAGE_MODE:
      list.push({ type: ISongs.BGM_RM_Loop, stopImmediately: [ISongs.BGM_BG_Base_Loop] });
      break;
    default:
      list.push({ type: ISongs.BGM_BG_Base_Loop });
      break;
  }
  AudioApi.playlist({ list });
};

export const getSlotOrderBySlotId = (slotId: SlotId): number => {
  switch (slotId) {
    case SlotId.WL:
      return 11;
    case SlotId.A:
      return 10;
    case SlotId.B:
      return 9;
    case SlotId.C:
      return 8;
    case SlotId.D:
      return 7;
    case SlotId.E:
      return 6;
    case SlotId.F:
      return 5;
    case SlotId.G:
      return 4;
    case SlotId.H:
      return 3;
    case SlotId.I:
      return 2;
    case SlotId.J:
      return 1;
    default:
      return 0;
  }
};

export const cascadeEase = (x: number): number => {
  const c1 = 1.70158;
  const c2 = c1 * 1;

  return x < 0.5 ? (Math.pow(2 * x, 2) * ((c2 + 1) * 2 * x - c2)) / 2 : Math.pow(x, 2);
};

export const isTabletPortrait = (_deviceWidth: number, _deviceHeight: number): boolean => {
  const isLandscape = _deviceWidth >= _deviceHeight;
  return isMobile.any && !isLandscape && _deviceWidth >= 768 && _deviceWidth < 1000;
};
export const isTabletLandscape = (_deviceWidth: number, _deviceHeight: number): boolean => {
  const isLandscape = _deviceWidth >= _deviceHeight;
  return isMobile.any && isLandscape && _deviceWidth >= 950 && _deviceHeight < 1200;
};
export const isMobilePortrait = (_deviceWidth: number, _deviceHeight: number): boolean => {
  const isLandscape = _deviceWidth >= _deviceHeight;
  return isMobile.any && !isLandscape && _deviceWidth < 768;
};
export const isMobileLandscape = (_deviceWidth: number, _deviceHeight: number): boolean => {
  const isLandscape = _deviceWidth >= _deviceHeight;
  return isMobile.any && isLandscape && _deviceWidth < 950;
};
