import { Spine } from 'pixi-spine';
import { Container, Graphics, Loader, Sprite, Texture } from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { isMobileLandscape, isMobilePortrait, isTabletLandscape, isTabletPortrait } from '../../utils';
import { ViewContainer } from '../components/ViewContainer';
import { SLOTS_CONTAINER_HEIGHT, SLOTS_CONTAINER_WIDTH, eventManager } from '../config';
import type { IGameContainer } from '../d';
import CoinsAnimationContainer from '../winAnimations/coinsAnimationContainer';
import type WinCountUpMessage from '../winAnimations/winCountUpMessage';

import { FreeSpinsLeft } from './freeSpinsLeft';
import ProgressBar from './progressBar';
import ProgressBarFreeSpins from './progressBarFreeSpins';

class GameView extends ViewContainer {
  private mainContainer: Sprite;

  public winSlotsContainer: Container;

  public miniPayTableContainer: Container;

  public reelsBackgroundContainer: Container;

  public reelsContainer: Container;

  public slotsContainer: Container;

  public winCountUpMessage: WinCountUpMessage;

  public progressBar: ProgressBar;

  public coinsAnimationContainer: Container;

  public frame: Sprite;

  public frameAmbientBack: Spine;

  public frameAmbientFront: Spine;

  public gameLogo: Spine;

  public freeSpinsLeft: FreeSpinsLeft;

  public progressBarFreeSpins: ProgressBarFreeSpins;

  public partyModeLabel: Sprite;

  public maskArea: Graphics;

  constructor(props: IGameContainer) {
    super();
    this.sortableChildren = true;
    this.mainContainer = new Sprite(Texture.EMPTY);
    this.mainContainer.name = 'mainContainer';
    this.mainContainer.anchor.set(0.5);
    this.addChild(this.mainContainer);
    this.slotsContainer = new Container();
    this.slotsContainer.name = 'slotsContainer';
    this.slotsContainer.x = -SLOTS_CONTAINER_WIDTH / 2;
    this.slotsContainer.y = -SLOTS_CONTAINER_HEIGHT / 2;
    this.maskArea = new Graphics()
      .beginFill(0xffffff)
      .drawRect(0, 0, SLOTS_CONTAINER_WIDTH, SLOTS_CONTAINER_HEIGHT)
      .endFill();
    this.maskArea.x = -SLOTS_CONTAINER_WIDTH / 2 + SLOTS_CONTAINER_WIDTH / 2;
    this.maskArea.y = 0;
    this.slotsContainer.interactive = true;
    this.progressBar = new ProgressBar();
    this.freeSpinsLeft = new FreeSpinsLeft();
    this.gameLogo = this.initGameLogo();
    this.coinsAnimationContainer = new CoinsAnimationContainer();
    this.coinsAnimationContainer.x = this.slotsContainer.x;
    this.coinsAnimationContainer.y = this.slotsContainer.y;
    this.winSlotsContainer = props.winSlotsContainer;
    this.winSlotsContainer.x = this.slotsContainer.x;
    this.winSlotsContainer.y = this.slotsContainer.y;
    this.miniPayTableContainer = props.miniPayTableContainer;
    this.miniPayTableContainer.x = this.slotsContainer.x;
    this.miniPayTableContainer.y = this.slotsContainer.y;
    this.reelsBackgroundContainer = props.reelsBackgroundContainer;
    this.reelsContainer = props.reelsContainer;
    this.winCountUpMessage = props.winCountUpMessage;
    this.slotsContainer.addChild(this.reelsContainer);
    this.slotsContainer.addChild(this.maskArea);
    this.slotsContainer.mask = this.maskArea;
    this.progressBarFreeSpins = new ProgressBarFreeSpins();
    this.frame = this.initDesktopReelsFrame();
    this.frameAmbientBack = this.initFrameAmbientBack();
    this.frameAmbientFront = this.initFrameAmbientFront();
    this.partyModeLabel = this.initPartyModeLabel();
    this.mainContainer.addChild(this.frameAmbientBack);
    this.mainContainer.addChild(this.reelsBackgroundContainer);
    this.mainContainer.addChild(this.slotsContainer);
    this.mainContainer.addChild(this.frame);
    this.mainContainer.addChild(this.progressBar);
    this.mainContainer.addChild(this.progressBarFreeSpins);
    this.mainContainer.addChild(this.frameAmbientFront);
    this.mainContainer.addChild(this.miniPayTableContainer);
    this.mainContainer.addChild(this.partyModeLabel);
    this.mainContainer.addChild(this.gameLogo);
    this.mainContainer.addChild(this.freeSpinsLeft);
    this.mainContainer.addChild(this.winSlotsContainer);
    this.mainContainer.addChild(this.coinsAnimationContainer);
    this.mainContainer.addChild(this.winCountUpMessage);
    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resizeGameContainer.bind(this));
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.handleBaseModeChanges();
        break;
      case GameMode.FREE_SPINS:
        this.handleFreeSpinsChanges();
        break;
      case GameMode.RAGE_MODE:
        this.handleRageModeChanges();
        break;
      default:
        this.handleBaseModeChanges();
        break;
    }
  }

  private handleBaseModeChanges(): void {
    this.progressBar.visible = true;
    this.progressBarFreeSpins.visible = false;
    this.partyModeLabel.visible = false;
    this.gameLogo.state.setAnimation(0, 'logo_base_game', true);
    this.frame.texture = Texture.from(ResourceTypes.frame);
    this.frameAmbientBack.state.setAnimation(0, 'reel_normal_back', true);
    this.frameAmbientFront.state.setAnimation(0, 'reel_normal_front', true);
  }

  private handleFreeSpinsChanges(): void {
    this.progressBar.visible = false;
    this.progressBarFreeSpins.visible = true;
    this.partyModeLabel.visible = false;
    this.gameLogo.state.setAnimation(0, 'logo_free_spins', true);
    this.frame.texture = Texture.from(ResourceTypes.frameFreeSpins);
    this.frameAmbientBack.state.setAnimation(0, 'reel_free_spins_back', true);
    this.frameAmbientFront.state.setAnimation(0, 'reel_free_spins_front', true);
  }

  private handleRageModeChanges(): void {
    this.progressBar.visible = false;
    this.progressBarFreeSpins.visible = false;
    this.partyModeLabel.visible = true;
    this.gameLogo.state.setAnimation(0, 'logo_rage_mode', true);
    this.frame.texture = Texture.from(ResourceTypes.framePartyMode);
    this.frameAmbientBack.state.setAnimation(0, 'reel_rage_mode_back', true);
    this.frameAmbientFront.state.setAnimation(0, 'reel_rage_mode_front', true);
  }

  private initGameLogo(): Spine {
    const gameLogo = new Spine(Loader.shared.resources['logo']!.spineData!);
    gameLogo.x = -460;
    gameLogo.y = -440;
    gameLogo.name = 'gameLogo';
    gameLogo.state.setAnimation(0, 'logo_base_game', true);
    return gameLogo;
  }

  private initDesktopReelsFrame(): Sprite {
    const frame = new Sprite(Texture.from(ResourceTypes.frame));
    frame.y = 0;
    frame.x = 0;
    frame.anchor.set(0.5);
    frame.name = 'frameStatic';
    return frame;
  }

  private initFrameAmbientBack(): Spine {
    const frameAmbientBack = new Spine(Loader.shared.resources['reelAmbient']!.spineData!);
    frameAmbientBack.y = 0;
    frameAmbientBack.x = 0;
    frameAmbientBack.name = 'frameBackAnim';
    frameAmbientBack.state.setAnimation(0, 'reel_normal_back', true);

    return frameAmbientBack;
  }

  private initFrameAmbientFront(): Spine {
    const frameAmbientFront = new Spine(Loader.shared.resources['reelAmbient']!.spineData!);
    frameAmbientFront.y = 0;
    frameAmbientFront.x = 0;
    frameAmbientFront.name = 'frameFrontAnim';
    frameAmbientFront.state.setAnimation(0, 'reel_normal_front', true);

    return frameAmbientFront;
  }

  private initPartyModeLabel(): Sprite {
    const partyModeLabel = new Sprite(Texture.from(ResourceTypes.labelPartyMode));
    partyModeLabel.scale.set(0.9);
    partyModeLabel.position.set(-265, -515);
    partyModeLabel.visible = false;
    return partyModeLabel;
  }

  private resizeGameContainer(_width: number, _height: number, _deviceWidth: number, _deviceHeight: number): void {
    this.scale.set(_width / 1550);
    this.y = 0;

    if (isTabletLandscape(_deviceWidth, _deviceHeight)) {
      this.scale.set(_width / 1750);
    }
    if (isTabletPortrait(_deviceWidth, _deviceHeight)) {
      this.scale.set(_width / 1510);
      this.y = -this.height * 0.15;
    }
    if (isMobilePortrait(_deviceWidth, _deviceHeight)) {
      this.scale.set(_width / 1350);
      this.y = -this.height * 0.1;
    }
    if (isMobileLandscape(_deviceWidth, _deviceHeight)) {
      this.scale.set(_width / 1510);
    }
    this.x = _width / 2;
  }
}

export default GameView;
