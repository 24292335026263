import type { SlotId } from '../config';
import { type Cascade, EventTypes, type ReelSet } from '../global.d';
import { setIsErrorMessage, setSlotConfig, setUserLastBetResult } from '../gql/cache';
import { Logic } from '../logic';
import { eventManager } from '../slotMachine/config';
import type { Icon } from '../slotMachine/d';

import { normalizePosition } from './helper';

const normalize = (coord: number, layout: string[]) => {
  return coord < 0 ? layout.length - (Math.abs(coord) % layout.length) : coord % layout.length;
};

export const getSpinResult = ({
  reelPositions,
  reelSet,
  icons,
}: {
  reelPositions: number[];
  reelSet: ReelSet;
  icons: Icon[];
}): Icon[] => {
  const cols = 5;
  const rows = 4;

  return [...(Array(cols * rows) as Icon[])].map((_, index) => {
    const row = Math.floor(index / cols);
    const column = index % cols;
    const layout = reelSet.layout[column as number];

    const initialCoord = reelPositions[column as number];
    const coord = (initialCoord as number) + row;

    return (
      icons.find((icon) => icon.id === reelSet.layout[column as number]![normalize(coord, layout as string[])]) ||
      icons[0]
    );
  }) as Icon[];
};

export const getCascadeColumns = ({
  reelPositions,
  layout,
  cascades,
}: {
  reelPositions: number[];
  layout: SlotId[][];
  cascades: Cascade[];
}): Icon[][] => {
  const { icons } = setSlotConfig();
  const moreSlots: SlotId[][] = [[], [], [], [], []];
  cascades
    .filter((cascade) => !cascade.isRandomWilds)
    .forEach((cascade) => {
      cascade.cascadeFall.reverse().forEach((cascadeFalls, _index) => {
        cascadeFalls.forEach((slot, idx) => {
          if (slot !== '') {
            moreSlots[idx as number]!.push(slot);
          }
        });
      });
    });
  const res = reelPositions.map((position, index) => {
    const start = position + 3;
    const slots: Icon[] = [];
    for (let i = 0; i < 4; i++) {
      const position = normalizePosition(layout[index as number]!.length, start - i);
      slots.push(icons.find((icon) => icon.id === layout[index as number]![position as number])!);
    }
    for (let i = 0; i < moreSlots[index as number]!.length; i++) {
      slots.push(icons.find((icon) => icon.id === moreSlots[index as number]![i as number])!);
    }

    return slots.reverse();
  });
  return res;
};

export const fallBackReelPosition = () => {
  setIsErrorMessage(true);
  const slotData = setSlotConfig();
  let reelSet;
  if (setUserLastBetResult().id) {
    if (setUserLastBetResult().reelSet) {
      reelSet = setUserLastBetResult().reelSet;
    } else {
      reelSet = slotData.reels.find((reelSet) => reelSet.id === setUserLastBetResult().reelSetId)!;
    }
  } else {
    reelSet = slotData.reels[0];
  }
  if (reelSet?.layout) {
    const startPosition = setUserLastBetResult().id
      ? setUserLastBetResult().result.reelPositions
      : slotData.settings.startPosition;
    eventManager.emit(
      EventTypes.SETUP_REEL_POSITIONS,
      getCascadeColumns({
        reelPositions: startPosition,
        layout: reelSet?.layout!,
        cascades: [],
      }),
      Logic.the.isStoppedBeforeResult,
    );

    eventManager.emit(EventTypes.END_WAITING_ANIMATION);
    eventManager.emit(EventTypes.FORCE_STOP_AUTOPLAY);
  }
};
