import i18n from 'i18next';
import * as _ from 'lodash';
import { ITextStyle, Sprite, Texture } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs } from '../../config';
import { EventTypes, FeatureState, IBonus } from '../../global.d';
import { setBetAmount, setBonuses, setCoinAmount, setCurrency, setSlotConfig } from '../../gql/cache';
import type { IBetSettings } from '../../gql/d';
import { getBetsSetting } from '../../gql/fromFragment';
import { ResourceTypes } from '../../resources.d';
import { getBonusIdByFeature, normalizeCoins, showCurrency } from '../../utils';
import {
  betValueStyle,
  modeButtonTextStyle,
  titleBaseTextStyle,
  totalCostTextStyle,
  totalCostValueTextStyle,
} from '../buyFeature/textStyles';
import { FeatureButton } from '../components/FeatureButton';
import { SpriteButton, SpriteButtonState } from '../components/SpriteButton';
import { TextField } from '../components/TextField';
import { PopupTypes, eventManager } from '../config';
import type { LineSet } from '../d';

import { PopupController } from './PopupController';
import { Popup } from './popup';

class BuyFeaturePopup extends Popup {
  private popupBg: Sprite;

  private baseModeBtn: FeatureButton;

  private rageModeBtn: FeatureButton;

  private confirmBtn: SpriteButton;

  private cancelBtn: SpriteButton;

  private titleText: TextField;

  private betAmountTitle: TextField;

  private totalCostText: TextField;

  private minusBtn: SpriteButton;

  private plusBtn: SpriteButton;

  private betAmountBackplate: Sprite;

  private betSettings: IBetSettings;

  private betAmount: number;

  private coinMultiplier: number;

  private currency = 'FUN';

  private betValue: TextField;

  private totalCostValue: TextField;

  private isNoFunds: boolean;

  private balance: number;

  private featureState: FeatureState = FeatureState.BASE;

  constructor() {
    super();
    this.betSettings = getBetsSetting();
    this.x = 0;
    this.y = 0;
    this.visible = false;
    this.coinMultiplier = (setSlotConfig().lineSets[0] as LineSet).coinAmountMultiplier;
    this.balance = 0;
    this.isNoFunds = false;
    this.interactive = true;
    this.currency = setCurrency();
    this.popupBg = this.initPopupBg();
    this.baseModeBtn = this.initBaseModeBtn();
    this.rageModeBtn = this.initRageModeBtn();
    this.titleText = this.initTitle();
    this.betAmountTitle = this.initBetAmountTitle();
    this.betAmountBackplate = this.initBetAmountBackplate();
    this.betAmount = this.getBetAmount(setBetAmount());
    this.minusBtn = this.initMinusBtn();
    this.plusBtn = this.initPlusBtn();
    this.betValue = this.initBetValue();
    this.totalCostText = this.initTotalCostText();
    this.totalCostValue = this.initTotalCostValue();
    this.cancelBtn = this.initCancelBtn();
    this.confirmBtn = this.initConfirmBtn();
    this.init();
    eventManager.on(EventTypes.UPDATE_BET, () => {
      this.betAmount = this.getBetAmount(setBetAmount());
      this.updateBets();
      this.handleDisable();
    });
    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, () => {
      PopupController.the.closeCurrentPopup();
    });
    eventManager.on(EventTypes.UPDATE_USER_BALANCE, (balance: { currency: string; amount: number }) => {
      this.balance = balance.amount / 100;
      this.handleDisable();
    });
  }

  public override show(): void {
    super.show();
    this.closeAllAnimationsInSlot();
    this.visible = true;
  }

  public close(): void {
    AudioApi.play({ type: ISongs.SFX_UI_Close });
    this.visible = false;
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    eventManager.emit(EventTypes.CLOSE_POPUP);
  }

  private init(): void {
    this.addChild(this.popupBg);
    this.popupBg.addChild(this.baseModeBtn);
    this.popupBg.addChild(this.rageModeBtn);
    this.popupBg.addChild(this.titleText.getText());
    this.popupBg.addChild(this.betAmountTitle.getText());
    this.popupBg.addChild(this.betAmountBackplate);
    this.popupBg.addChild(this.betValue.getText());
    this.popupBg.addChild(this.minusBtn);
    this.popupBg.addChild(this.plusBtn);
    this.popupBg.addChild(this.totalCostText.getText());
    this.popupBg.addChild(this.totalCostValue.getText());
    this.popupBg.addChild(this.cancelBtn);
    this.popupBg.addChild(this.confirmBtn);
  }

  private initPopupBg(): Sprite {
    const popupBg = new Sprite(Texture.from(ResourceTypes.buyFeaturePopupConfirm));
    popupBg.name = 'popupBg';
    popupBg.x = 0;
    popupBg.y = 0;
    popupBg.anchor.set(0.5);

    return popupBg;
  }

  private initBaseModeBtn(): FeatureButton {
    const baseBtn = new FeatureButton({
      [SpriteButtonState.ACTIVE]: {
        texture: Texture.from(ResourceTypes.buyFeatureFreeSpinsPressed),
        textStyle: modeButtonTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.buyFeatureFreeSpins),
        textStyle: modeButtonTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.buyFeatureFreeSpinsHover),
        textStyle: modeButtonTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.buyFeatureFreeSpinsPressed),
        textStyle: modeButtonTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.buyFeatureFreeSpinsDisabled),
        textStyle: modeButtonTextStyle as Partial<ITextStyle>,
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.handleBaseBtnClick.bind(this),
      onTouchStart: this.handleBaseBtnClick.bind(this),
      isActive: true,
      textFiled: this.initFreeSpinsButtonText(),
    });
    baseBtn.name = 'baseModeBtn';
    baseBtn.y = -170;
    baseBtn.x = -baseBtn.width / 2;

    return baseBtn;
  }

  private initRageModeBtn(): FeatureButton {
    const rageBtn = new FeatureButton({
      [SpriteButtonState.ACTIVE]: {
        texture: Texture.from(ResourceTypes.buyFeaturePartyModePressed),
        textStyle: modeButtonTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.buyFeaturePartyMode),
        textStyle: modeButtonTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.buyFeaturePartyModeHover),
        textStyle: modeButtonTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.buyFeaturePartyModePressed),
        textStyle: modeButtonTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.buyFeaturePartyModeDisabled),
        textStyle: modeButtonTextStyle as Partial<ITextStyle>,
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.handleRageBtnClick.bind(this),
      onTouchStart: this.handleRageBtnClick.bind(this),
      textFiled: this.initFreeSpinsPartyModeButtonText(),
    });
    rageBtn.name = 'rageModeBtn';
    rageBtn.y = -170;
    rageBtn.x = rageBtn.width / 2;

    return rageBtn;
  }

  private initTitle(): TextField {
    const title = new TextField(
      i18n.t<string>('buyFeatureTitleBase', { spinsNumber: 10 }),
      400,
      100,
      titleBaseTextStyle as Partial<ITextStyle>,
    );
    title.text.name = 'title';
    title.text.y = -70;
    title.text.x = 0;
    title.text.anchor.set(0.5);

    return title;
  }

  private initBetAmountTitle(): TextField {
    const betAmountTitle = new TextField(
      i18n.t<string>('buyFeatureBetPerGame'),
      400,
      100,
      totalCostTextStyle as Partial<ITextStyle>,
    );
    betAmountTitle.text.name = 'betAmountTitle';
    betAmountTitle.text.y = -25;
    betAmountTitle.text.x = 0;
    betAmountTitle.text.anchor.set(0.5, 0);

    return betAmountTitle;
  }

  private initMinusBtn(): SpriteButton {
    const minusBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.buyFeatureMinusBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.buyFeatureMinusBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.buyFeatureMinusBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.buyFeatureMinusBtnDisable),
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.handleMinus.bind(this),
      onTouchStart: this.handleMinus.bind(this),
    });
    minusBtn.name = 'minusBtn';
    minusBtn.y = 40;
    minusBtn.x = -104;
    minusBtn.anchor.set(1, 0.5);

    return minusBtn;
  }

  private initBetAmountBackplate(): Sprite {
    const input = new Sprite(Texture.from(ResourceTypes.buyFeaturePopupBackplate));
    input.name = 'betAmountBackplate';
    input.y = 40;
    input.x = 0;
    input.anchor.set(0.5);

    return input;
  }

  private initBetValue(): TextField {
    const betValue = new TextField(
      `${formatNumber({
        currency: this.currency,
        value: normalizeCoins(this.getBetValue()),
        showCurrency: showCurrency(this.currency),
      })}`,
      300,
      100,
      betValueStyle as Partial<ITextStyle>,
    );
    betValue.text.name = 'betValue';
    betValue.text.y = 40;
    betValue.text.x = 0;
    betValue.text.anchor.set(0.5);

    return betValue;
  }

  private initPlusBtn(): SpriteButton {
    const plusBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.buyFeaturePlusBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.buyFeaturePlusBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.buyFeaturePlusBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.buyFeaturePlusBtnDisable),
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.handlePlus.bind(this),
      onTouchStart: this.handlePlus.bind(this),
    });
    plusBtn.name = 'plusBtn';
    plusBtn.y = 40;
    plusBtn.x = 104;
    plusBtn.anchor.set(0, 0.5);

    return plusBtn;
  }

  private initTotalCostText(): TextField {
    const totalCostText = new TextField(
      i18n.t<string>('buyFeatureTotalCost'),
      400,
      100,
      totalCostTextStyle as Partial<ITextStyle>,
    );
    totalCostText.text.name = 'totalCostText';
    totalCostText.text.y = 100;
    totalCostText.text.x = 0;
    totalCostText.text.anchor.set(0.5);

    return totalCostText;
  }

  private initTotalCostValue(): TextField {
    const betValue = new TextField(
      this.getTotalCost(this.featureState),
      300,
      100,
      totalCostValueTextStyle as Partial<ITextStyle>,
    );
    betValue.text.name = 'totalCostValue';
    betValue.text.y = 118;
    betValue.text.x = 0;
    betValue.text.anchor.set(0.5, 0);

    return betValue;
  }

  private initCancelBtn(): SpriteButton {
    const cancelBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.buyFeatureCancelBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.buyFeatureCancelBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.buyFeatureCancelBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.buyFeatureCancelBtnDisable),
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.onCancel.bind(this),
      onTouchStart: this.onCancel.bind(this),
    });
    cancelBtn.name = 'cancelBtn';
    cancelBtn.y = 205;
    cancelBtn.x = -42;
    cancelBtn.anchor.set(1, 0.5);
    return cancelBtn;
  }

  private initConfirmBtn(): SpriteButton {
    const confirmBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.buyFeatureConfirmBtnDefault),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.buyFeatureConfirmBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.buyFeatureConfirmBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.buyFeatureConfirmBtnDisabled),
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.handleClickOk.bind(this),
      onTouchStart: this.handleClickOk.bind(this),
    });
    confirmBtn.name = 'confirmBtn';
    confirmBtn.y = 205;
    confirmBtn.x = 42;
    confirmBtn.anchor.set(0, 0.5);
    return confirmBtn;
  }

  public override hide(): void {
    super.hide();
    this.visible = false;
  }

  private handleBaseBtnClick(): void {
    this.changeFeatureState(FeatureState.BASE);
    this.popupBg.texture = Texture.from(ResourceTypes.buyFeaturePopupConfirm);
    this.titleText.setText(
      i18n.t<string>('buyFeatureTitleBase', {
        spinsNumber: 10,
      }),
    );
    this.totalCostText.setStyle(totalCostTextStyle);
    this.betAmountTitle.setStyle(totalCostTextStyle);
    this.rageModeBtn.turnOff();
    this.baseModeBtn.turnOn();
    this.updateBets();
  }

  private handleRageBtnClick(): void {
    this.changeFeatureState(FeatureState.RAGE);
    this.popupBg.texture = Texture.from(ResourceTypes.buyFeaturePopup);
    this.titleText.setText(
      i18n.t<string>('buyFeatureTitleRage', {
        spinsNumber: 25,
      }),
    );
    this.totalCostText.setStyle(totalCostTextStyle);
    this.betAmountTitle.setStyle(totalCostTextStyle);
    this.rageModeBtn.turnOn();
    this.baseModeBtn.turnOff();
    this.updateBets();
  }

  private onCancel() {
    AudioApi.play({ type: ISongs.SFX_UI_Close });
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    eventManager.emit(EventTypes.ENABLE_PAYTABLE);
    PopupController.the.closeCurrentPopup();
  }

  private initFreeSpinsButtonText(): TextField {
    const freeSpinsText = new TextField(
      i18n.t<string>('freeSpinsButtonText'),
      180,
      70,
      modeButtonTextStyle as Partial<ITextStyle>,
    );
    freeSpinsText.text.anchor.set(0.5, 0.5);
    return freeSpinsText;
  }

  private initFreeSpinsPartyModeButtonText(): TextField {
    const freeSpinsRageText = new TextField(
      i18n.t<string>('freeSpinsPartyModeButtonText'),
      180,
      70,
      modeButtonTextStyle as Partial<ITextStyle>,
    );
    freeSpinsRageText.text.anchor.set(0.5, 0.5);
    return freeSpinsRageText;
  }

  private getBetAmount = (betAmount: number): number => {
    return (
      _.findIndex(this.betSettings!.bets, (bet) => {
        return bet === betAmount / this.coinMultiplier;
      }) + 1
    );
  };

  private handleMinus = (): void => {
    if (this.betSettings.bets[this.betAmount - 1]! > this.betSettings!.minBet) {
      this.betAmount--;
      setCoinAmount(this.betSettings.bets[this.betAmount - 1]);
      this.updateBets();
      this.handleDisable();
      setBetAmount(setCoinAmount() * this.coinMultiplier);
      AudioApi.play({ type: ISongs.SFX_UI_BetChange });
    }
  };

  private handlePlus = (): void => {
    if (this.betSettings.bets[this.betAmount - 1]! < this.betSettings!.maxBet) {
      this.betAmount++;
      setCoinAmount(this.betSettings.bets[this.betAmount - 1]);
      this.updateBets();
      this.handleDisable();
      setBetAmount(setCoinAmount() * this.coinMultiplier);
      AudioApi.play({ type: ISongs.SFX_UI_BetChange });
    }
  };

  private changeFeatureState(state: FeatureState): void {
    this.featureState = state;
    AudioApi.play({ type: ISongs.SFX_UI_General });
    this.handleDisable();
  }

  private updateBets(): void {
    this.betValue.text.text = `${formatNumber({
      currency: this.currency,
      value: normalizeCoins(this.getBetValue()),
      showCurrency: showCurrency(this.currency),
    })}`;
    this.totalCostValue.text.text = this.getTotalCost(this.featureState);
  }

  private getTotalCost = (featureState: FeatureState): string => {
    return `${formatNumber({
      currency: this.currency,
      value: normalizeCoins(this.getBetValue() * this.getCoinAmount(featureState)),
      showCurrency: showCurrency(this.currency),
    })}`;
  };

  private getBetValue = (): number => {
    return this.coinMultiplier * (this.betSettings!.bets[this.betAmount - 1] || 1);
  };

  private getCoinAmount = (featureState: FeatureState): number => {
    const bonuses = setBonuses();
    const bonusId = getBonusIdByFeature(featureState);
    const bonus = _.chain(bonuses)
      .filter((bonus) => bonus.id === bonusId)
      .get(0, {})
      .value() as IBonus;

    return bonus.coinAmount;
  };

  private handleClickOk = (): void => {
    AudioApi.play({ type: ISongs.SFX_UI_General });
    if (!this.isNoFunds) {
      PopupController.the.openPopup(PopupTypes.BUY_FEATURE_CONFIRMATION, {
        totalCost: this.getTotalCost(this.featureState),
        coinAmount: this.betSettings.bets[this.betAmount] as number,
        featureState: this.featureState,
      });
    }
  };

  private handleDisable = (): void => {
    const bet = this.betSettings.bets[this.betAmount - 1];
    this.isNoFunds = this.balance < normalizeCoins(this.getBetValue() * this.getCoinAmount(this.featureState));
    if (this.isNoFunds) {
      this.confirmBtn.disable();
    } else {
      this.confirmBtn.enable();
    }
    if (bet === this.betSettings!.minBet) {
      this.minusBtn.disable();
    } else {
      this.minusBtn.enable();
    }

    if (bet === this.betSettings!.maxBet) {
      this.plusBtn.disable();
    } else {
      this.plusBtn.enable();
    }
  };

  private closeAllAnimationsInSlot(): void {
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
  }

  protected override resize(_width: number, _height: number): void {
    const isLandscape = _width >= _height;

    if (isLandscape && _width < 916) {
      this.scale.set(1.7);
    } else if (!isLandscape && _width < 768) {
      this.scale.set(2);
    } else {
      this.scale.set(1);
    }
  }
}

export default BuyFeaturePopup;
