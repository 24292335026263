import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';

import AudioApi from '@phoenix7dev/audio-api';
import { Environments } from '@phoenix7dev/audio-api/dist/d';
import { Loader } from '@phoenix7dev/shared-components';
import { ELoaderStages } from '@phoenix7dev/shared-components/dist/loader/d';
import { rebuildStorageCache } from '@phoenix7dev/utils-fe';

import { LOADER_MAPPED_SYMBOLS, LOADER_TEXTURES, audioSprite, audioSpriteVolume } from '../../config';
import {
  BonusStatus,
  EventTypes,
  GameMode,
  IAuthInput,
  IBonus,
  IUserBalance,
  UserBonus,
  bonusesId,
} from '../../global.d';
import {
  setBetAmount,
  setBonuses,
  setBottomContainerTotalWin,
  setBrokenGame,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setCurrentBonus,
  setFreeRoundsBonus,
  setFreeRoundsTotalWin,
  setFreeSpinsTotalWin,
  setGameMode,
  setIsAuthorized,
  setIsSoundOn,
  setIsTurboSpin,
  setProgress,
  setSlotConfig,
  setUserBalance,
  setUserLastBetResult,
  setWinAmount,
} from '../../gql/cache';
import client from '../../gql/client';
import type { IConfig, ISlotHistoryData } from '../../gql/d';
import { authGql } from '../../gql/mutation';
import {
  configGql,
  getBonuses,
  getProgressGql,
  getSlotGql,
  getUserBonuses,
  getUserGql,
  slotHistoryGql,
} from '../../gql/query';
import { ResourceTypes } from '../../resources.d';
import { eventManager } from '../../slotMachine/config';
import type { ISlotData } from '../../slotMachine/d';
import {
  findSubstituteCoinAmount,
  isBuyFeatureEnabled,
  loadErrorHandler,
  loadPixiAssets,
  parseQuery,
  wait,
} from '../../utils';
import { remoteStorage } from '../../utils/remoteStorage';
import Resources from '../../utils/resources';

import styles from './loadScreen.module.scss';

const LoadScreen: React.FC = () => {
  const { data } = useQuery<{
    progress: { status: number; wasLoaded?: boolean };
  }>(getProgressGql);

  const { data: config } = useQuery<IConfig>(configGql);
  const { isSoundOn } = config!;
  const [isShowContent] = useState(true);
  const { progress } = data!;

  const [getAuth] = useMutation<
    { auth: { sessionId: string } },
    { input: Omit<IAuthInput, 'slotId' | 'lng' | 'home'> }
  >(authGql, {
    onCompleted({ auth: { sessionId } }) {
      setSlotConfig({
        ...setSlotConfig(),
        sessionId,
      });
      setIsAuthorized(!!data);
    },
  });

  useEffect(() => {
    const getUserBalance = async () => {
      const userBalance = await client.query<{ user: IUserBalance }>({
        query: getUserGql,
        fetchPolicy: 'network-only',
      });
      setUserBalance(userBalance.data.user);
      setCurrency(userBalance.data.user.balance.currency);
    };
    const getLastBet = async () => {
      const betsData = await client.query<{ bets: ISlotHistoryData }>({
        query: slotHistoryGql,
        variables: {
          input: { last: 1, filter: { slotId: setSlotConfig().id } },
        },
        fetchPolicy: 'network-only',
      });
      if (betsData.data.bets.edges[0]) {
        setUserLastBetResult(betsData.data.bets.edges[0].node);
      }
    };
    const getPurchasableBonuses = async () => {
      const bonusData = await client.query<{ bonuses: IBonus[] }>({
        query: getBonuses,
        variables: { input: { purchasable: true, slotId: setSlotConfig().id } },
        fetchPolicy: 'network-only',
      });
      setBonuses(bonusData.data.bonuses);
    };
    const getSlotData = async () => {
      const slotData = await client.query<{ slot: ISlotData }>({
        query: getSlotGql,
        variables: { input: { id: setSlotConfig().id } },
        fetchPolicy: 'network-only',
      });
      const { slot } = slotData.data;
      setSlotConfig({
        ...setSlotConfig(),
        clientSettings: slot.clientSettings,
        settings: slot.settings,
        previewImage: slot.previewImage,
        icons: slot.icons,
        reels: slot.reels,
        lineSets: slot.lineSets,
        isBuyFeatureEnabled: isBuyFeatureEnabled(slot.clientSettings.features),
      });
      setGameMode(GameMode.BASE_GAME);
      let coinValue;
      let coinAmount;
      if (setBrokenGame()) {
        coinValue = setCurrentBonus().coinValue;
        coinAmount = setCurrentBonus().coinAmount;
      } else {
        const lastBetCoinAmount = setUserLastBetResult().id ? setUserLastBetResult().coinAmount : 1;
        coinAmount = findSubstituteCoinAmount(lastBetCoinAmount, slot.clientSettings.coinAmounts.default);
        coinValue = slot.clientSettings.coinValues.find((elem) => elem.code === setCurrency())?.variants[0];
      }
      setCoinValue(coinValue);
      setCoinAmount(coinAmount);
      setWinAmount(setUserLastBetResult().result.winCoinAmount);
      setBetAmount(coinAmount * slot.lineSets[0]!.coinAmountMultiplier);
    };
    const checkBrokenGame = async () => {
      const userBonusData = await client.query<{ userBonuses: UserBonus[] }>({
        query: getUserBonuses,
        variables: {
          input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
        },
        fetchPolicy: 'network-only',
      });

      if (userBonusData.data.userBonuses.length > 0) {
        setBrokenGame(true);

        const bonus = userBonusData.data.userBonuses.find(
          (e) => e.bonusId !== bonusesId[GameMode.FREE_ROUND_BONUS],
        ) as UserBonus;
        const frbBonus = userBonusData.data.userBonuses.find(
          (e) => e.bonusId === bonusesId[GameMode.FREE_ROUND_BONUS],
        ) as UserBonus;

        const frbTotalAmount = frbBonus?.totalWinAmount ? frbBonus?.totalWinAmount / frbBonus.coinValue : 0;

        const fsTotalAmount = bonus?.totalWinAmount ? bonus?.totalWinAmount / bonus.coinValue : 0;

        if (userBonusData.data.userBonuses.length === 1 && frbBonus) {
          setCurrentBonus({
            ...(frbBonus as UserBonus),
            isActive: true,
            gameMode: GameMode.FREE_ROUND_BONUS,
            currentRound: 0,
            rounds: frbBonus.rounds,
            totalWinAmount: frbBonus.totalWinAmount,
            coinAmount: frbBonus.coinAmount,
            coinValue: frbBonus.coinValue,
            id: frbBonus.id,
          });
          if (frbBonus.totalWinAmount) {
            setBottomContainerTotalWin(frbBonus.totalWinAmount);
            setFreeRoundsTotalWin(frbBonus.totalWinAmount);
          }
        } else {
          if (userBonusData.data.userBonuses.length > 1) {
            if (frbBonus && bonus.data.frbReferenceId) {
              setFreeRoundsBonus({
                ...frbBonus,
                isActive: true,
                gameMode: GameMode.FREE_ROUND_BONUS,
                currentRound: 0,
                rounds: frbBonus.rounds,
                totalWinAmount: frbBonus.totalWinAmount,
                coinAmount: frbBonus.coinAmount,
                coinValue: frbBonus.coinValue,
                id: frbBonus.id,
              });
              setFreeRoundsTotalWin(frbTotalAmount - fsTotalAmount);
            }
            setFreeSpinsTotalWin(fsTotalAmount);
            setBottomContainerTotalWin(frbBonus && bonus.data.frbReferenceId ? frbTotalAmount : fsTotalAmount);
          } else {
            setFreeSpinsTotalWin(fsTotalAmount);
            setBottomContainerTotalWin(fsTotalAmount);
          }
          const freeSpinGameMode =
            bonus.data.cascadeMultiplier === 10 || bonus.cascadeMultiplier === 10
              ? GameMode.RAGE_MODE
              : GameMode.FREE_SPINS;

          setCurrentBonus({
            ...(bonus as UserBonus),
            isActive: true,
            gameMode: freeSpinGameMode,
            currentRound: bonus.roundsPlayed,
            rounds: bonus.rounds + bonus.roundsPlayed,
            totalWinAmount: bonus.totalWinAmount,
            coinAmount: bonus.coinAmount,
            coinValue: bonus.coinValue,
            id: bonus.id,
          });
        }
      }
    };
    new Loader({ asynchronous: false })
      .stage(10, ELoaderStages.AUTH, async (stage) => {
        const { token, clientId } = parseQuery<Omit<IAuthInput, 'slotId' | 'lng'>>();
        const { data } = await getAuth({ variables: { input: { token, clientId } } });

        window.remoteStorage = remoteStorage;
        await remoteStorage.init(data?.auth.sessionId as string);
        rebuildStorageCache<IConfig>('config', {
          isSoundOn: setIsSoundOn,
          isTurboSpin: setIsTurboSpin,
        });

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(30, ELoaderStages.BONUS_GAME, async (stage) => {
        await getUserBalance();
        await getPurchasableBonuses();
        await getLastBet();
        await checkBrokenGame();
        await getSlotData();
        setProgress({
          ...setProgress(),
          status: stage,
        });
      })

      .stage(40, ELoaderStages.PIXI_ASSETS, async (stage) => {
        await loadPixiAssets([...LOADER_MAPPED_SYMBOLS, ...LOADER_TEXTURES], process.env.PUBLIC_URL);
        setProgress({
          ...setProgress(),
          status: stage,
        });
        await wait(500);
      })
      .stage(60, ELoaderStages.IMAGES, async (stage) => {
        setProgress({
          ...setProgress(),
          status: stage,
        });
        await wait(500);
      })
      .stage(80, ELoaderStages.AUDIO, async (_stage) => {
        AudioApi.initialize({
          audioSprite,
          audioVolume: audioSpriteVolume,
          restricted: false,
          isSoundEnabled: isSoundOn,
          onSuspended: () => {},
          audioBaseUrl: `${process.env.PUBLIC_URL}/sound`,
          environment: window.__ENV__?.ENV ?? Environments.DEVELOPMENT,
        }).then(() => {
          eventManager.emit(EventTypes.SOUND_INITIALIZED);
        });
      })
      .onError(async (error, resources) => {
        loadErrorHandler(error, resources);
      })
      .onComplete(async () => {
        setProgress({
          ...setProgress(),
          status: 100,
        });
        eventManager.on(EventTypes.GAME_READY, () => {
          setProgress({
            ...setProgress(),
            wasLoaded: true,
          });
          // setShowContent(false);
        });
      })
      .load();
  }, []);

  if (!isShowContent) return null;
  return (
    <div className={styles['loadScreenWrapper']}>
      <div className={styles['logo']}>
        <img
          draggable="false"
          alt="logo"
          src={Resources.getSource(ResourceTypes.logo)}
          className={styles['companyLogo']}
        />
        <div className={styles['companyLogo_loaded']} style={{ height: `${progress?.status}%` }}>
          <img draggable="false" alt="logoLoaded" src={Resources.getSource(ResourceTypes.logo)} />
        </div>
      </div>
    </div>
  );
};

export default LoadScreen;
