import * as PIXI from 'pixi.js';

import type { RemoteStorage } from '@phoenix7dev/setting-store-client';

import { SlotId } from './config';
import { ReelSetType } from './gql/d';
import { Icon, LineSet } from './slotMachine/d';

declare global {
  interface Window {
    __ENV__: {
      REACT_APP_TRANSLATE_URL: string;
      ENV: string;
      STATIC_CDN_URL: string;
      STATIC_CDN_DIRECTORY: string;
      APP_VERSION: string;
      APP_NAME: string;
      SENTRY_ENABLED: boolean;
      SENTRY_DSN: string;
      NETWORK_RETRY_ATTEMPTS: number;
      NETWORK_RETRY_DELAY: number;
    };
    PIXI: PIXI;
    __PIXI_APP__: PIXI.Application;
    eventManager: PIXI.utils.EventEmitter;
    remoteStorage: RemoteStorage;
  }
}

export interface IUserBalance {
  id: string;
  clientId: string;
  balance: {
    amount: number;
    currency: string;
  };
}

export interface IAuthInput {
  clientId: string;
  token: string;
  slotId: string;
  lng: string;
  home: string;
}

export interface IGetBonusesInput {
  id: string;
  purchasable: boolean;
}

export interface IBuyBonusesInput {
  id: string;
  coinValue: number;
}

export interface Cascade {
  isRandomWilds: boolean;
  winPositions: number[][];
  winAmounts: number[];
  cascadeFall: (SlotId | '')[][];
}
export interface ISettledBet {
  bet: {
    id: string;
    coinAmount: number;
    coinValue: number;
    slotId: string;
    result: {
      reelPositions: number[];
      winCoinAmount: number;
      spinResult: Icon[];
    };
    userBonusId: string;
    userBonus: {
      id: string;
      bonusId: string;
      lineSet: LineSet;
    };
    reelSetId: string;
    reelSet: ReelSet;
    lineSet: LineSet;
    data: {
      bonuses: UserBonus[];
      features: {
        cascade: Cascade[];
        multiplier: number;
        scatterWins: {
          rewards: {
            multiplier?: number;
          }[];
        }[];
      };
    };
    createdAt: string;
    updatedAt: string;
  };
  paylines: {
    lineId: number;
    winPositions: number[];
    amount: number;
  }[];
  balance: {
    placed: {
      amount: number;
      currency: string;
    };
    settled: {
      amount: number;
      currency: string;
    };
  };
}
export type ReelSet = {
  id: string;
  layout: SlotId[][];
  type: ReelSetType;
  additionalReelSets: SlotId[][];
};
export type UserBonus = {
  bonusId: string;
  id: string;
  isActive: boolean;
  gameMode: GameMode;
  currentRound: number;
  betId: string;
  coinAmount: number;
  coinValue: number;
  rounds: number;
  roundsPlayed: number;
  status: UserBonusStatus;
  data: {
    frbReferenceId?: string;
    cascadeMultiplier: number;
  };
  isFreeBet?: boolean;
  cascadeMultiplier: number;
  reelSetId?: string;
  totalWinAmount: number;
};

export enum GameMode {
  BASE_GAME,
  FREE_SPINS,
  RAGE_MODE,
  BUY_FEATURE,
  FREE_ROUND_BONUS,
}

export type BuyFeatureEnterProps = {
  bonusId: string;
};
export type FreeSpinsEnterProps = {
  bonus: UserBonus;
};

export type EnterProps = (BuyFeatureEnterProps | FreeSpinsEnterProps) & {
  immediate?: boolean;
  skipIdle?: boolean;
  endBonus?: boolean;
};

export enum FeatureState {
  BASE,
  RAGE,
}
export const baseReelSetIds = [
  '1374bacb-a390-4766-a9bc-5802d9a0af44',
  '70990cb0-b1f0-41e9-808c-fcc0e5008d65',
  'fa30ba09-14c8-4f3f-ba92-de0bfff80981',
];
export const bonusesId = {
  [GameMode.FREE_SPINS]: '317f8b3a-bd2d-4f35-9d87-6d0c5c29a708',
  [GameMode.FREE_ROUND_BONUS]: '59bf10b3-a0e7-4346-86bc-cfca305ee896',
};
export const buyFeatureBonusesId = {
  [FeatureState.BASE]: '8c021823-6f71-4232-8674-d970e25185a9',
  [FeatureState.RAGE]: 'eb095db2-f7e7-4da5-aa07-34390856cca6',
};
export interface IBonus {
  id: string;
  type: string;
  coinAmount: number;
  isActive: boolean;
  bonusId: string;
  roundsPlayed: number;
  currentRound: number;
  rounds: number;
  reelSetId: string;
  totalWinAmount: number;
  cascadeMultiplier: number;
  betId: string;
}
export interface GetUserBonusesInput {
  id: string;
  status: BonusStatus;
}

export enum UserBonusStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SETTLED = 'SETTLED',
}

export type FreeSpinsTitleProps = {
  text: string;
  spins: string;
  currentSpin: string;
};

export type MessageBannerProps = {
  title?: string;
  titlePosition?: number;
  mobileTitlePosition?: number;
  titleStyles?: TextStyle;
  mobileTitleStyle?: TextStyle;
  subtitle?: string;
  subtitlePosition?: number;
  mobileSubtitlePosition?: number;
  subtitleStyles?: TextStyle;
  mobileSubtitleStyle?: TextStyle;
  btnText?: string;
  additionalText?: string;
  additionalPosition?: number;
  additionalStyles?: TextStyle;
  mobileAdditionalStyle?: TextStyle;
  preventDefaultDestroy?: boolean;
  mobileSubtitleBorderWidth?: number;
  mobileSubtitleBorderHeight?: number;
  subtitleBorderWidth?: number;
  subtitleBorderHeight?: number;
  callback?: () => void;
  onInitCallback?: () => void;
};

export enum EventTypes {
  BROKEN_GAME = 'brokenGame',
  END_BIG_WIN_PRESENTATION = 'endBigWinPresentation',
  CHANGE_MODE = 'changeMode',
  CLOSE_ALL_STARS = 'closeAllStars',
  COUNT_UP_END = 'countUpEnd',
  CLOSE_ALL_MULTIPLIER_STARS = 'closeAllMultiplierStart',
  DISABLE_ALL_MINI_PAY_TABLES = 'disableAllMiniPayTables',
  DISABLE_PAYTABLE = 'disablePaytable',
  END_TWEEN_ANIMATION = 'endTweenAnimation',
  END_CASCADE_FEATURE = 'endCascadeFeature',
  END_FREE_SPINS = 'endFreeSpins',
  ENABLE_PAYTABLE = 'enablePaytable',
  IMMEDIATE_CLOSE_STARS = 'immediateCloseStars',
  IMMEDIATE_CLOSE_ALL_MULTIPLIER_STARS = 'immediateCloseAllMultiplierStars',
  FORCE_STOP_REELS = 'forceStopReels',
  FORCE_RESIZE = 'forceResize',
  GAME_READY = 'gameReady',
  HIDE_COINS = 'hideCoins',
  HIDE_COUNT_UP = 'hideCountUp',
  HIDE_WIN_LABEL = 'hideWinLabel',
  HIDE_WIN_COUNT_UP_MESSAGE = 'hideWinCountUpMessage',
  HANDLE_SKIP_FADE_ANIMATION = 'handleSkipFadeAnimation',
  HANDLE_START_FADE_ANIMATION = 'handleStartFadeAnimation',
  HANDLE_BUY_BONUS = 'handleBuyBonus',
  HANDLE_UPDATE_FREE_SPINS_TITLE = 'handleUpdateFreeSpinsTitle',
  NEXT_FREE_SPINS_ROUND = 'nextFreeSpinsRound',
  NEXT_CASCADE = 'nextCascade',
  MULTIPLIER_STAR_WIN = 'multiplierStarWin',
  OPEN_STAR = 'openStar',
  OPEN_MULTIPLIER_STAR = 'openMultiplierStar',
  SPACEKEY_CLOSE_MESSAGE_BANNER = 'spaceKeyCloseMessageBanner',
  REELS_STOPPED = 'reelsStopped',
  REEL_LANDED = 'reelLanded',
  RESET_SLOT_MACHINE = 'resetSlotMachine',
  RESIZE = 'resize',
  RESIZE_GAME_CONTAINER = 'resizeGameContainer',
  REMOVE_TWEEN_ANIMATION = 'removeTweenAnimation',
  ROLLBACK_REELS = 'rollbackReels',
  SET_SLOTS_VISIBILITY = 'setSlotsVisibility',
  START_TWEEN_ANIMATION = 'startTweenAnimation',
  START_WIN_ANIMATION = 'startWinAnimation',
  START_SPIN_ANIMATION = 'startSpinAnimation',
  START_MODE_CHANGE_FADE = 'startModeChangeFade',
  START_BUY_FEATURE_ROUND = 'startBuyFeatureRound',
  START_COUNT_UP = 'startCountUp',
  START_MULTIPLIER_ANIMATION = 'startMultiplierAnimation',
  START_BIG_WIN_PRESENTATION = 'startBigWinPresentation',
  START_GENERAL_RANDOM_WILDS = 'startGeneralRandomWilds',
  START_SINGLE_RANDOM_WILD = 'startSingleRandomWilds',
  START_CASCADE_FEATURE = 'startCascadeFeature',
  START_FREE_SPINS = 'startFreeSpins',
  START_RAGE_MODE = 'startRageMode',
  START_RANDOM_WILDS_ANIMATION = 'startRandomWildsAnimation',
  SHOW_PAY_TABLE = 'showPayTable',
  SHOW_COINS = 'showCoins',
  SHOW_WIN_LABEL = 'showWinLabel',
  SOUND_INITIALIZED = 'soundInitialized',
  SET_CURRENT_RESULT_MINI_PAYTABLE = 'setCurrentResultMiniPayTable',
  SETUP_REEL_POSITIONS = 'setupReelPositions',
  SKIP_ALL_WIN_ANIMATIONS = 'skipAllWinAnimations',
  SKIP_WIN_COUNT_UP_ANIMATION = 'skipWinCountUpAnimation',
  THROW_ERROR = 'throwError',
  UPDATE_BET = 'updateBet',
  UPDATE_USER_BALANCE = 'updateUserBalance',
  UPDATE_TOTAL_WIN_VALUE = 'updateTotalWinValue',
  UPDATE_WIN_VALUE = 'updateWinValue',
  POST_RENDER = 'postrender',
  OPEN_POPUP = 'openPopup',
  OPEN_POPUP_BG = 'openPopupBg',
  CLOSE_POPUP = 'closePopup',
  CLOSE_POPUP_BG = 'closePopupBg',
  DISABLE_BUY_FEATURE_BTN = 'disableBuyFeatureBtn',
  SET_IS_AUTO_SPINS = 'setIsAutoSpins',
  SET_AUTO_SPINS_LEFT = 'setAutoSpinsLeft',
  SET_IS_SPIN_IN_PROGRESS = 'isSpinInProgress',
  SET_IS_SLOT_IS_BUSY = 'isSlotBusy',
  SET_IS_SLOTS_STOPPED = 'isSlotsStopped',
  SET_IS_FREESPINS_WIN = 'isFreeSpinWin',
  TOGGLE_SOUND = 'toggleSound',
  TOGGLE_TURBO_SPIN = 'toggleTurboSpin',
  TOGGLE_SPIN = 'toggleSpin',
  HANDLE_DESTROY_INTRO_SCREEN = 'handleDestroyIntroScreen',
  WIN_STAR = 'winStar',
  PHOENIX_ANTICIPATION_START = 'phoenixStart',
  PHOENIX_ANTICIPATION_END = 'phoenixEnd',
  PHOENIX_ANTICIPATION_SKIP = 'phoenixSkip',
  END_WAITING_ANIMATION = 'removeErrorHandler',
  FORCE_STOP_AUTOPLAY = 'forceStopAutoplay',
  START_FREE_ROUND_BONUS = 'startFreeRoundBonus',
  END_FREE_ROUND_BONUS = 'endFreeRoundBonus',
  UPDATE_FREE_ROUNDS_LEFT = 'updateFreeRoundsLeft',
  FREE_ROUND_BONUS_EXPIRED = 'freeRoundBonusExpired',
}

export enum BonusStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SETTLED = 'SETTLED',
}

export enum FeatureTypes {
  SPECIAL_ROUND = 'SPECIAL_ROUND',
  FREE_SPIN = 'FREE_SPIN',
}
export enum GraphQLErrorsType {
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
}

export enum PopupOpeningTypes {
  MENU = 'MENU',
  AUTOPLAY_POPUP = 'AUTOPLAY_POPUP',
  BET_SETTINGS_POPUP = 'BET_SETTINGS_POPUP',
  BUY_FEATURE_POPUP = 'BUY_FEATURE_POPUP',
  NONE = null,
  INFO_POPUP = 'INFO_POPUP',
  HISTORY_POPUP = 'HISTORY_POPUP',
}
