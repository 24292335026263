import { ResourceTypes } from '../resources.d';
import Resources from '../utils/resources';

import { SlotId } from './config';

export const MAPPED_SYMBOLS: Record<SlotId, { default: ResourceTypes; freespin?: ResourceTypes }> = {
  [SlotId.A]: { default: ResourceTypes.a },
  [SlotId.B]: { default: ResourceTypes.b },
  [SlotId.C]: { default: ResourceTypes.c },
  [SlotId.D]: { default: ResourceTypes.d },
  [SlotId.E]: { default: ResourceTypes.e },
  [SlotId.F]: { default: ResourceTypes.f },
  [SlotId.G]: { default: ResourceTypes.g },
  [SlotId.H]: { default: ResourceTypes.h },
  [SlotId.I]: { default: ResourceTypes.i },
  [SlotId.J]: { default: ResourceTypes.j },
  [SlotId.WL]: { default: ResourceTypes.wL },
};

export enum SymbolAnimationType {
  DEFAULT,
  SPRITE,
  SPINE,
}

export const MAPPED_SYMBOLS_WIN_ANIMATIONS: {
  [key in SlotId]: {
    type: SymbolAnimationType;
    src?: string;
    animation?: string;
  };
} = {
  [SlotId.A]: {
    type: SymbolAnimationType.SPINE,
    src: 'a_symbol',
    animation: 'win',
  },
  [SlotId.B]: {
    type: SymbolAnimationType.SPINE,
    src: 'b_symbol',
    animation: 'win',
  },
  [SlotId.C]: {
    type: SymbolAnimationType.SPINE,
    src: 'c_symbol',
    animation: 'hp_3_win',
  },
  [SlotId.D]: {
    type: SymbolAnimationType.SPINE,
    src: 'd_symbol',
    animation: 'hp_4_win',
  },
  [SlotId.E]: {
    type: SymbolAnimationType.SPINE,
    src: 'e_symbol',
    animation: 'hp_5_win',
  },
  [SlotId.F]: {
    type: SymbolAnimationType.SPINE,
    src: 'f_symbol',
    animation: 'win',
  },
  [SlotId.G]: {
    type: SymbolAnimationType.SPINE,
    src: 'g_symbol',
    animation: 'win',
  },
  [SlotId.H]: {
    type: SymbolAnimationType.SPINE,
    src: 'h_symbol',
    animation: 'lp_3_win',
  },
  [SlotId.I]: {
    type: SymbolAnimationType.SPINE,
    src: 'i_symbol',
    animation: 'lp_4_win',
  },
  [SlotId.J]: {
    type: SymbolAnimationType.SPINE,
    src: 'j_symbol',
    animation: 'win',
  },
  [SlotId.WL]: {
    type: SymbolAnimationType.SPINE,
    src: 'wild',
    animation: 'wild_win',
  },
};

export const MAPPED_SYMBOLS_LAND_ANIMATIONS: {
  [key in SlotId]: {
    type: SymbolAnimationType;
    src?: string;
    animation?: string;
  };
} = {
  [SlotId.A]: {
    type: SymbolAnimationType.SPINE,
    src: 'a_symbol',
    animation: 'land',
  },
  [SlotId.B]: {
    type: SymbolAnimationType.SPINE,
    src: 'b_symbol',
    animation: 'land',
  },
  [SlotId.C]: {
    type: SymbolAnimationType.SPINE,
    src: 'c_symbol',
    animation: 'hp_3_land',
  },
  [SlotId.D]: {
    type: SymbolAnimationType.SPINE,
    src: 'd_symbol',
    animation: 'hp_4_land',
  },
  [SlotId.E]: {
    type: SymbolAnimationType.SPINE,
    src: 'e_symbol',
    animation: 'hp_5_land',
  },
  [SlotId.F]: {
    type: SymbolAnimationType.SPINE,
    src: 'f_symbol',
    animation: 'land',
  },
  [SlotId.G]: {
    type: SymbolAnimationType.SPINE,
    src: 'g_symbol',
    animation: 'land',
  },
  [SlotId.H]: {
    type: SymbolAnimationType.SPINE,
    src: 'h_symbol',
    animation: 'lp_3_land',
  },
  [SlotId.I]: {
    type: SymbolAnimationType.SPINE,
    src: 'i_symbol',
    animation: 'lp_4_land',
  },
  [SlotId.J]: {
    type: SymbolAnimationType.SPINE,
    src: 'j_symbol',
    animation: 'land',
  },
  [SlotId.WL]: {
    type: SymbolAnimationType.SPINE,
    src: 'wild',
    animation: 'wild_land',
  },
};
export const LOADER_MAPPED_SYMBOLS = [
  { name: ResourceTypes.a, src: Resources.getSource(ResourceTypes.a) },
  { name: ResourceTypes.b, src: Resources.getSource(ResourceTypes.b) },
  {
    name: ResourceTypes.c,
    src: Resources.getSource(ResourceTypes.c),
  },
  {
    name: ResourceTypes.d,
    src: Resources.getSource(ResourceTypes.d),
  },
  { name: ResourceTypes.e, src: Resources.getSource(ResourceTypes.e) },
  {
    name: ResourceTypes.f,
    src: Resources.getSource(ResourceTypes.f),
  },
  {
    name: ResourceTypes.g,
    src: Resources.getSource(ResourceTypes.g),
  },
  {
    name: ResourceTypes.h,
    src: Resources.getSource(ResourceTypes.h),
  },
  {
    name: ResourceTypes.i,
    src: Resources.getSource(ResourceTypes.i),
  },
  {
    name: ResourceTypes.j,
    src: Resources.getSource(ResourceTypes.j),
  },
  {
    name: ResourceTypes.wL,
    src: Resources.getSource(ResourceTypes.wL),
  },
];

export const LOADER_TEXTURES = [
  { name: ResourceTypes.frame, src: Resources.getSource(ResourceTypes.frame) },
  {
    name: ResourceTypes.reelBackground,
    src: Resources.getSource(ResourceTypes.reelBackground),
  },
  {
    name: ResourceTypes.reelBackgroundPartyMode,
    src: Resources.getSource(ResourceTypes.reelBackgroundPartyMode),
  },
  {
    name: ResourceTypes.reelBackgroundFreeSpins,
    src: Resources.getSource(ResourceTypes.reelBackgroundFreeSpins),
  },
  {
    name: ResourceTypes.framePartyMode,
    src: Resources.getSource(ResourceTypes.framePartyMode),
  },
  {
    name: ResourceTypes.frameFreeSpins,
    src: Resources.getSource(ResourceTypes.frameFreeSpins),
  },
  {
    name: ResourceTypes.buyFeatureFreeSpins,
    src: Resources.getSource(ResourceTypes.buyFeatureFreeSpins),
  },
  {
    name: ResourceTypes.buyFeatureFreeSpinsPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureFreeSpinsPressed),
  },
  {
    name: ResourceTypes.buyFeatureFreeSpinsHover,
    src: Resources.getSource(ResourceTypes.buyFeatureFreeSpinsHover),
  },
  {
    name: ResourceTypes.buyFeatureFreeSpinsDisabled,
    src: Resources.getSource(ResourceTypes.buyFeatureFreeSpinsDisabled),
  },
  {
    name: ResourceTypes.buyFeaturePartyMode,
    src: Resources.getSource(ResourceTypes.buyFeaturePartyMode),
  },
  {
    name: ResourceTypes.buyFeaturePartyModePressed,
    src: Resources.getSource(ResourceTypes.buyFeaturePartyModePressed),
  },
  {
    name: ResourceTypes.buyFeaturePartyModeHover,
    src: Resources.getSource(ResourceTypes.buyFeaturePartyModeHover),
  },
  {
    name: ResourceTypes.buyFeaturePartyModeDisabled,
    src: Resources.getSource(ResourceTypes.buyFeaturePartyModeDisabled),
  },
  {
    name: 'coinsAnimation',
    src: '/animations/coins/coins.json',
  },
  {
    name: 'a_symbol',
    src: '/animations/symbol_win/hp_1.json',
  },
  {
    name: 'b_symbol',
    src: '/animations/symbol_win/hp_2.json',
  },
  {
    name: 'c_symbol',
    src: '/animations/symbol_win/hp_3.json',
  },
  {
    name: 'd_symbol',
    src: '/animations/symbol_win/hp_4.json',
  },
  {
    name: 'e_symbol',
    src: '/animations/symbol_win/hp_5.json',
  },
  {
    name: 'f_symbol',
    src: '/animations/symbol_win/lp_1.json',
  },
  {
    name: 'g_symbol',
    src: '/animations/symbol_win/lp_2.json',
  },
  {
    name: 'h_symbol',
    src: '/animations/symbol_win/lp_3.json',
  },
  {
    name: 'i_symbol',
    src: '/animations/symbol_win/lp_4.json',
  },
  {
    name: 'j_symbol',
    src: '/animations/symbol_win/lp_5.json',
  },
  {
    name: 'wild',
    src: '/animations/symbol_win/wild.json',
  },
  {
    name: 'explosion',
    src: '/animations/symbol_win/symbol_explosion.json',
  },
  {
    name: 'winFrame',
    src: '/animations/symbol_win/win_frame.json',
  },
  {
    name: 'generalRandomWilds',
    src: '/animations/randomWilds/features_full_screen.json',
  },
  {
    name: 'meteorWild',
    src: '/animations/randomWilds/wild_features.json',
  },
  {
    name: 'reelAmbient',
    src: '/animations/reelAmbient/reel.json',
  },
  {
    name: 'bigWinMessages',
    src: '/animations/winMessages/special_messages.json',
  },
  {
    name: 'progress_bar_win',
    src: '/animations/progressBar/progress_bar_win.json',
  },
  {
    name: 'progress_bar',
    src: '/animations/progressBar/progress_bar.json',
  },
  {
    name: 'popups',
    src: '/animations/popups/pop_ups.json',
  },
  {
    name: 'multipliers',
    src: '/animations/multipliers/multiplayers.json',
  },
  {
    name: 'logo',
    src: '/animations/logo/logo.json',
  },
  {
    name: 'background',
    src: '/animations/background/background.json',
  },
  {
    name: 'ui',
    src: '/images/ui/ui.json',
  },
  {
    name: ResourceTypes.labelPartyMode,
    src: Resources.getSource(ResourceTypes.labelPartyMode),
  },
  {
    name: ResourceTypes.slotBg,
    src: Resources.getSource(ResourceTypes.slotBg),
  },
  {
    name: ResourceTypes.slotBgFreeSpins,
    src: Resources.getSource(ResourceTypes.slotBgFreeSpins),
  },
  {
    name: ResourceTypes.slotBgPartyMode,
    src: Resources.getSource(ResourceTypes.slotBgPartyMode),
  },
  {
    name: ResourceTypes.introBg,
    src: Resources.getSource(ResourceTypes.introBg),
  },
  {
    name: ResourceTypes.introReel1,
    src: Resources.getSource(ResourceTypes.introReel1),
  },
  {
    name: ResourceTypes.introReel2,
    src: Resources.getSource(ResourceTypes.introReel2),
  },
  {
    name: ResourceTypes.introReel3,
    src: Resources.getSource(ResourceTypes.introReel3),
  },
  {
    name: ResourceTypes.buyFeatureBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureBtn),
  },
  {
    name: ResourceTypes.buyFeatureBtnDisable,
    src: Resources.getSource(ResourceTypes.buyFeatureBtnDisable),
  },
  {
    name: ResourceTypes.buyFeatureBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureBtnPressed),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtn),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtnPressed),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtnDisable,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtnDisable),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtn,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtn),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtnHover),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtnPressed),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtnDisable,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtnDisable),
  },
  {
    name: ResourceTypes.buyFeatureConfirmBtnDefault,
    src: Resources.getSource(ResourceTypes.buyFeatureConfirmBtnDefault),
  },
  {
    name: ResourceTypes.buyFeatureConfirmBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureConfirmBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureConfirmBtnDisabled,
    src: Resources.getSource(ResourceTypes.buyFeatureConfirmBtnDisabled),
  },
  {
    name: ResourceTypes.buyFeatureConfirmBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureConfirmBtnPressed),
  },
  {
    name: ResourceTypes.buyFeatureCancelBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureCancelBtn),
  },
  {
    name: ResourceTypes.buyFeatureCancelBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureCancelBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureCancelBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureCancelBtnPressed),
  },
  {
    name: ResourceTypes.buyFeatureCancelBtnDisable,
    src: Resources.getSource(ResourceTypes.buyFeatureCancelBtnDisable),
  },
  {
    name: ResourceTypes.buyFeaturePopup,
    src: Resources.getSource(ResourceTypes.buyFeaturePopup),
  },
  {
    name: ResourceTypes.backdrop,
    src: Resources.getSource(ResourceTypes.backdrop),
  },
  {
    name: ResourceTypes.buyFeaturePopupConfirm,
    src: Resources.getSource(ResourceTypes.buyFeaturePopupConfirm),
  },
  {
    name: ResourceTypes.buyFeaturePopupBackplate,
    src: Resources.getSource(ResourceTypes.buyFeaturePopupBackplate),
  },
  {
    name: ResourceTypes.freeSpinsCounter,
    src: Resources.getSource(ResourceTypes.freeSpinsCounter),
  },
  {
    name: 'phoenixMovie',
    src: '/movie/phoenixAnticipation.mp4',
  },
];
