import { Sprite, Texture } from 'pixi.js';

import { GameMode } from '../../../global.d';
import { ResourceTypes } from '../../../resources.d';
import { ViewContainer } from '../../components/ViewContainer';

class ReelsBackgroundContainer extends ViewContainer {
  private background: Sprite;

  constructor() {
    super();
    this.background = this.initBackground();
    this.background.anchor.set(0.5);
    this.addChild(this.background);
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.background.texture = Texture.from(ResourceTypes.reelBackground);
        break;
      case GameMode.FREE_SPINS:
        this.background.texture = Texture.from(ResourceTypes.reelBackgroundFreeSpins);
        break;
      case GameMode.RAGE_MODE:
        this.background.texture = Texture.from(ResourceTypes.reelBackgroundPartyMode);
        break;
      default:
        this.background.texture = Texture.from(ResourceTypes.reelBackground);
        break;
    }
  }

  initBackground(): Sprite {
    const texture = Texture.from(ResourceTypes.reelBackground);
    const sprite = new Sprite(texture);
    sprite.x = 0;
    sprite.y = 0;
    return sprite;
  }
}

export default ReelsBackgroundContainer;
