import { Spine } from 'pixi-spine';
import { ITextStyle, Loader } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, Variables } from '../../config';
import { EventTypes } from '../../global.d';
import { TextField } from '../components/TextField';
import { ViewContainer } from '../components/ViewContainer';
import { eventManager } from '../config';

const STARS_AMOUNT = 5;
const STARS_SCALES = [1.1, 1.2, 1.3, 1.4, 1.5];
const EYES_POSITIONS = [0, 95, 200, 320, 450];
const START_MULTIPLIERS = ['x2', 'x3', 'x5', 'x7', 'x10'];

const freeSpinsMultiplierTextStyle: Partial<ITextStyle> = {
  fill: '#ffffff',
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 32,
  fontWeight: 'bold',
  stroke: '#DE7C12',
  strokeThickness: 8,
  lineJoin: 'round',
  whiteSpace: 'normal',
};
const freeSpinsMultiplierTextInactiveStyle: Partial<ITextStyle> = {
  fill: '#ffffff',
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 32,
  fontWeight: 'bold',
  stroke: '#5E3915',
  strokeThickness: 8,
  lineJoin: 'round',
  whiteSpace: 'normal',
};
const freeSpinsMultiplierTextActiveStyle: Partial<ITextStyle> = {
  fill: '#ffffff',
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 32,
  fontWeight: 'bold',
  stroke: '#FF5C00',
  strokeThickness: 8,
  lineJoin: 'round',
  whiteSpace: 'normal',
};

class ProgressBarFreeSpins extends ViewContainer {
  private backgroundAnimation: Spine;

  private progressBarStarSpines: Spine[];

  private progressBarStarTexts: TextField[];

  private startState: number[] = [];

  constructor() {
    super();
    this.x = 55;
    this.y = -405;
    this.visible = false;
    this.backgroundAnimation = this.initBackgroundAnimation();
    this.progressBarStarSpines = this.initProgressBarSpines();
    this.progressBarStarTexts = this.initProgressBarTexts();
    this.resetStarState();
    this.initSubscriptions();
    this.addChild(this.backgroundAnimation, ...this.progressBarStarSpines);
  }

  private initSubscriptions(): void {
    eventManager.on(EventTypes.OPEN_MULTIPLIER_STAR, (id: number) => {
      AudioApi.play({ type: ISongs.Progressbar_Fill, stopPrev: true });
      if (id < STARS_AMOUNT) {
        this.startState[id as number] = 1;
        eventManager.emit(EventTypes.START_MULTIPLIER_ANIMATION, START_MULTIPLIERS[id as number]);
        (this.progressBarStarTexts[id as number] as TextField).text.alpha = 1;
        (this.progressBarStarTexts[id as number] as TextField).setStyle(freeSpinsMultiplierTextStyle);
        (this.progressBarStarTexts[id as number] as TextField).text.style.fontSize = 32 + id * 2;
        (this.progressBarStarSpines[id as number] as Spine).state.setAnimation(0, 'eye_activation', false);
        (this.progressBarStarSpines[id as number] as Spine).state.addAnimation(0, 'eye_activation_idle', true, 0);
      } else {
        eventManager.emit(EventTypes.START_MULTIPLIER_ANIMATION, START_MULTIPLIERS[STARS_AMOUNT - 1]);
      }
    });
    eventManager.on(EventTypes.MULTIPLIER_STAR_WIN, () => {
      AudioApi.play({ type: ISongs.Progressbar_Win });
      const id = this.startState.lastIndexOf(1);
      (this.progressBarStarTexts[id as number] as TextField).text.alpha = 1;
      (this.progressBarStarTexts[id as number] as TextField).setStyle(freeSpinsMultiplierTextActiveStyle);
      (this.progressBarStarTexts[id as number] as TextField).text.style.fontSize = 32 + id * 2;
      this.backgroundAnimation.state.setAnimation(0, 'pb_win_fs', false);
      (this.progressBarStarSpines[id as number] as Spine).state.setAnimation(0, 'eye_win', false);
      (this.progressBarStarSpines[id as number] as Spine).state.addAnimation(0, 'eye_win_idle', true, 0);
    });
    eventManager.on(EventTypes.CLOSE_ALL_MULTIPLIER_STARS, () => {
      const lastIndex = this.startState.lastIndexOf(1);
      for (let i = 0; i < STARS_AMOUNT; i++) {
        if (this.startState[i as number] !== 0) {
          (this.progressBarStarTexts[i as number] as TextField).text.alpha = 0.5;
          (this.progressBarStarTexts[i as number] as TextField).setStyle(freeSpinsMultiplierTextInactiveStyle);
          (this.progressBarStarTexts[i as number] as TextField).text.style.fontSize = 32 + i * 2;
          (this.progressBarStarSpines[i as number] as Spine).state.setAnimation(
            0,
            i === lastIndex ? 'eye_off_3' : 'eye_off_2',
            false,
          );
          (this.progressBarStarSpines[i as number] as Spine).state.addAnimation(0, 'eye_close_static', true, 0);
        }
      }
      this.resetStarState();
    });
    eventManager.on(EventTypes.IMMEDIATE_CLOSE_ALL_MULTIPLIER_STARS, () => {
      for (let i = 0; i < STARS_AMOUNT; i++) {
        (this.progressBarStarTexts[i as number] as TextField).text.alpha = 0.5;
        (this.progressBarStarTexts[i as number] as TextField).setStyle(freeSpinsMultiplierTextInactiveStyle);
        (this.progressBarStarTexts[i as number] as TextField).text.style.fontSize = 32 + i * 2;
        (this.progressBarStarSpines[i as number] as Spine).state.setAnimation(0, 'eye_close_static', true);
      }
      this.resetStarState();
    });
  }

  private initBackgroundAnimation(): Spine {
    const animation = new Spine(Loader.shared.resources['progress_bar_win']!.spineData!);
    animation.y = -20;
    animation.x = -70;
    return animation;
  }

  private initProgressBarSpines(): Spine[] {
    const animations = [];
    for (let i = 0; i < STARS_AMOUNT; i++) {
      const animation = new Spine(Loader.shared.resources['progress_bar']!.spineData!);
      animation.state.setAnimation(0, 'eye_close_static', true);
      animation.scale.set(STARS_SCALES[i as number]);
      animation.y = -animation.height / 2;
      animation.x = EYES_POSITIONS[i as number] as number;
      animations.push(animation);
    }
    return animations;
  }

  private initProgressBarTexts(): TextField[] {
    const texts: TextField[] = [];
    for (let i = 0; i < STARS_AMOUNT; i++) {
      const text = new TextField(START_MULTIPLIERS[i as number], 65, 75, freeSpinsMultiplierTextInactiveStyle);
      text.text.x = -2;
      text.text.style.fontSize = 32 + i * 2;
      text.text.alpha = 0.5;
      text.text.anchor.set(0.5);
      (this.progressBarStarSpines[i as number] as Spine).addChild(text.getText());
      texts.push(text);
    }
    return texts;
  }

  private resetStarState(): void {
    for (let i = 0; i < STARS_AMOUNT; i++) {
      this.startState[i as number] = 0;
    }
  }
}

export default ProgressBarFreeSpins;
