import i18n from 'i18next';
import { Spine } from 'pixi-spine';
import { ITextStyle, Loader, Sprite, Texture } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, Variables, mappedAudioSprites } from '../../config';
import { EventTypes, GameMode } from '../../global.d';
import { setIsDuringBigWinLoop } from '../../gql/cache';
import { Logic } from '../../logic';
import { delayedAction } from '../../utils';
import type Animation from '../animations/animation';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { TextField } from '../components/TextField';
import { PopupTypes, eventManager } from '../config';
import type { PopupProps } from '../d';

import { PopupController } from './PopupController';
import { Popup } from './popup';

const textStyle: Partial<ITextStyle> = {
  align: 'center',
  fill: ['#fff', '#FFEE57'],
  fillGradientStops: [0, 1],
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 90,
  fontWeight: 'bold',
  miterLimit: 48,
  stroke: '#4A00A9',
  strokeThickness: 18,
  whiteSpace: 'normal',
  wordWrapWidth: 140,
  lineJoin: 'round',
};
export class RageModePopup extends Popup {
  protected background: Sprite;

  protected spine: Spine;

  protected text: TextField;

  protected backgroundFadeInAnimation: Animation;

  protected backgroundFadeOutAnimation: Animation;

  private spineListener = {
    complete: () => {
      eventManager.removeListener(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.bindedCallback);
      this.backgroundFadeOutAnimation.start();
      this.removeListener('click', this.bindedCallback);
      this.removeListener('touchstart', this.bindedCallback);
      this.visible = false;
      PopupController.the.openPopup(PopupTypes.RAGE_MODE_INFO);
    },
  };

  private callback = () => {
    this.spine.state.removeListener(this.spineListener);
    eventManager.removeListener(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.bindedCallback);
    this.removeListener('click', this.bindedCallback);
    this.removeListener('touchstart', this.bindedCallback);
    this.visible = false;
    PopupController.the.openPopup(PopupTypes.RAGE_MODE_INFO);
  };

  private bindedCallback = this.callback.bind(this);

  constructor() {
    super();
    this.interactive = true;
    this.visible = false;
    this.text = new TextField(i18n.t('freeSpinsActivated'), 440, 100, textStyle);
    this.text.text.anchor.set(0.5, 0.5);
    this.background = this.initBackground();
    this.spine = this.initSpine();
    this.backgroundFadeInAnimation = this.initBackGroundFadeInAnimation();
    this.backgroundFadeOutAnimation = this.initBackGroundFadeOutAnimation();
    this.addChild(this.background);
    this.addChild(this.spine);
  }

  private initBackground(): Sprite {
    const sprite = new Sprite(Texture.WHITE);
    sprite.tint = 0x000000;
    sprite.anchor.set(0.5, 0.5);
    return sprite;
  }

  protected initSpine(): Spine {
    const spine = new Spine(Loader.shared.resources['popups']!.spineData!);
    return spine;
  }

  private initBackGroundFadeInAnimation(): Animation {
    return new Tween({
      object: this.background,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 0,
      target: 0.7,
      duration: 500,
    });
  }

  private initBackGroundFadeOutAnimation(): Animation {
    return new Tween({
      object: this.background,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 0.7,
      target: 0,
      duration: 500,
    });
  }

  protected override resize(_width: number, _height: number): void {
    this.background.width = _width;
    this.background.height = _height;
    this.position.set(_width / 2, _height / 2);
    const isLandscape = _width >= _height;
    if (isLandscape) {
      const factor = Math.min(_height / 1080, _width / 1920);
      this.spine.scale.set(factor);
    } else {
      const factor = Math.min(_height / 1920, _width / 1080);
      this.spine.scale.set(factor);
    }
  }

  public override show(_props?: PopupProps): void {
    AudioApi.play({ type: ISongs.RM_EntrancePopup });
    delayedAction(mappedAudioSprites[ISongs.RM_EntrancePopup].duration, () => {
      if (
        !(
          PopupController.the.currentPopup &&
          PopupController.the.currentPopup === PopupController.the.popups.get(PopupTypes.FREE_SPINS_END)
        ) &&
        Logic.the.controller.gameMode === GameMode.RAGE_MODE
      ) {
        AudioApi.play({ type: ISongs.BGM_RM_Loop });
      }
      if (setIsDuringBigWinLoop()) {
        AudioApi.fadeOut(0, ISongs.BGM_RM_Loop);
      }
    });

    const factor = this.spine.scale.x;
    this.removeChild(this.spine);
    this.spine = new Spine(Loader.shared.resources['popups']!.spineData!);

    this.spine.scale.set(factor);
    this.addChildAt(this.spine, 1);
    this.on('click', this.bindedCallback);
    this.on('touchstart', this.bindedCallback);
    this.visible = true;
    eventManager.once(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.bindedCallback);

    this.backgroundFadeInAnimation.start();
    this.spine.state.addListener(this.spineListener);
    this.spine.state.setAnimation(10, 'rage_mode_pop_up', false);
    const textPlaceholder = this.spine.skeleton.findSlot('activated_place_holder').currentSprite as Sprite;
    textPlaceholder.texture = Texture.EMPTY;
    textPlaceholder.addChild(this.text.getText());
  }
}
