import { useReactiveVar } from '@apollo/client';
import type React from 'react';
import { useTranslation } from 'react-i18next';

import { setSlotConfig } from '../../gql/cache';
import type { ISlotConfig } from '../../gql/d';
import { ResourceTypes } from '../../resources.d';
import Resources from '../../utils/resources';

import styles from './info.module.scss';

const CascadeFeature: React.VFC = () => {
  const i18n = useTranslation();
  const { t } = i18n;
  const infoCascadeFeatureDescs1 = [
    { key: 'infoCascadeFeatureDesc_1', text: i18n.t('infoCascadeFeatureDesc_1') },
    { key: 'infoCascadeFeatureDesc_2', text: i18n.t('infoCascadeFeatureDesc_2') },
    { key: 'infoCascadeFeatureDesc_3', text: i18n.t('infoCascadeFeatureDesc_3') },
  ];
  const infoCascadeFeatureDescs2 = [
    { key: 'infoCascadeFeatureDesc_4', text: i18n.t('infoCascadeFeatureDesc_4') },
    { key: 'infoCascadeFeatureDesc_5', text: i18n.t('infoCascadeFeatureDesc_5') },
  ];
  return (
    <section className={styles['feature']}>
      <h1 className={styles['subtitle']}>{t('infoCascadeFeatureTitle')}</h1>
      <div className={styles['row']}>
        <div className={styles['col']}>
          {infoCascadeFeatureDescs1.map((o) => (
            <p className={`${styles['p']}`} key={o.key} dangerouslySetInnerHTML={{ __html: o.text }} />
          ))}
        </div>
        <div className={styles['col']}>
          {infoCascadeFeatureDescs2.map((o) => (
            <p className={`${styles['p']}`} key={o.key} dangerouslySetInnerHTML={{ __html: o.text }} />
          ))}
        </div>
      </div>
    </section>
  );
};

const FreeSpins: React.VFC = () => {
  const i18n = useTranslation();
  const { t } = i18n;
  const infoFreeSpinsDescs1 = [
    { key: 'infoFreeSpinsDescs_1', text: i18n.t('infoFreeSpinsDescs_1') },
    { key: 'infoFreeSpinsDescs_2', text: i18n.t('infoFreeSpinsDescs_2') },
  ];
  const infoFreeSpinsDescs2 = [
    { key: 'infoFreeSpinsDescs_3', text: i18n.t('infoFreeSpinsDescs_3') },
    { key: 'infoFreeSpinsDescs_4', text: i18n.t('infoFreeSpinsDescs_4') },
  ];

  return (
    <section className={styles['feature']}>
      <h1 className={styles['subtitle']}>{t('infoFreeSpinsTitle')}</h1>
      <div className={styles['row']}>
        <div className={styles['col']}>
          {infoFreeSpinsDescs1.map((o) => (
            <p className={`${styles['p']}`} key={o.key} dangerouslySetInnerHTML={{ __html: o.text }} />
          ))}
        </div>
        <div className={styles['col']}>
          {infoFreeSpinsDescs2.map((o) => (
            <p className={`${styles['p']}`} key={o.key} dangerouslySetInnerHTML={{ __html: o.text }} />
          ))}
        </div>
      </div>
    </section>
  );
};

const FreeSpinsFeature: React.VFC = () => {
  const i18n = useTranslation();
  const { t } = i18n;
  const infoFreeSpinsFeatureDescs1 = [
    { key: 'infoFreeSpinsFeatureDescs_1', text: i18n.t('infoFreeSpinsFeatureDescs_1') },
    { key: 'infoFreeSpinsFeatureDescs_2', text: i18n.t('infoFreeSpinsFeatureDescs_2') },
  ];
  const infoFreeSpinsFeatureDescs2 = [
    { key: 'infoFreeSpinsFeatureDescs_3', text: i18n.t('infoFreeSpinsFeatureDescs_3') },
    { key: 'infoFreeSpinsFeatureDescs_4', text: i18n.t('infoFreeSpinsFeatureDescs_4') },
  ];

  return (
    <section className={styles['feature']}>
      <h1 className={styles['subtitle']}>{t('infoFreeSpinsFeatureDescs')}</h1>
      <div className={styles['row']}>
        <div className={styles['col']}>
          {infoFreeSpinsFeatureDescs1.map((o) => (
            <p className={styles['p']} key={o.key} dangerouslySetInnerHTML={{ __html: o.text }} />
          ))}
        </div>
        <div className={styles['col']}>
          {infoFreeSpinsFeatureDescs2.map((o) => (
            <p className={styles['p']} key={o.key} dangerouslySetInnerHTML={{ __html: o.text }} />
          ))}
        </div>
      </div>
    </section>
  );
};

const RageModeFeature: React.VFC = () => {
  const i18n = useTranslation();
  const { t } = i18n;
  const infoRageModeFeatureDescs1 = [
    { key: 'infoRageModeFeatureDescs_1', text: i18n.t('infoRageModeFeatureDescs_1') },
    { key: 'infoRageModeFeatureDescs_2', text: i18n.t('infoRageModeFeatureDescs_2') },
  ];
  const infoRageModeFeatureDescs2 = [{ key: 'infoRageModeFeatureDescs_3', text: i18n.t('infoRageModeFeatureDescs_3') }];

  return (
    <section className={styles['feature']}>
      <h1 className={styles['subtitle']}>{t('infoRageModeFeatureTitle')}</h1>
      <div className={styles['row']}>
        <div className={styles['col']}>
          {infoRageModeFeatureDescs1.map((o) => (
            <p className={styles['p']} key={o.key} dangerouslySetInnerHTML={{ __html: o.text }} />
          ))}
        </div>
        <div className={styles['col']}>
          {infoRageModeFeatureDescs2.map((o) => (
            <p className={styles['p']} key={o.key} dangerouslySetInnerHTML={{ __html: o.text }} />
          ))}
        </div>
      </div>
    </section>
  );
};

const RandomWildsFeature: React.VFC = () => {
  const { t } = useTranslation();
  return (
    <section className={styles['feature']}>
      <h1 className={styles['subtitle']}>{t('infoRandomWildsFeatureTitle')}</h1>
      <div className={styles['row']}>
        <div className={styles['col']}>
          <p className={styles['p']} dangerouslySetInnerHTML={{ __html: t('infoRandomWildsFeatureDescs_1') }} />
        </div>
        <div className={styles['col']}>
          <p className={styles['p']} dangerouslySetInnerHTML={{ __html: t('infoRandomWildsFeatureDescs_2') }} />
        </div>
      </div>
    </section>
  );
};

const BuyFeatureFunction: React.VFC = () => {
  const i18n = useTranslation();
  const { t } = i18n;
  const infoFeatureBuy1 = [
    { key: 'infoFeatureBuyDesc_1', text: i18n.t('infoFeatureBuyDesc_1') },
    { key: 'infoFeatureBuyDesc_2', text: i18n.t('infoFeatureBuyDesc_2') },
    { key: 'infoFeatureBuyDesc_3', text: i18n.t('infoFeatureBuyDesc_3') },
  ];
  const infoFeatureBuy2 = [
    { key: 'infoFeatureBuyDesc_4', text: i18n.t('infoFeatureBuyDesc_4') },
    { key: 'infoFeatureBuyDesc_5', text: i18n.t('infoFeatureBuyDesc_5') },
    { key: 'infoFeatureBuyDesc_6', text: i18n.t('infoFeatureBuyDesc_6') },
  ];
  if (!setSlotConfig().isBuyFeatureEnabled) {
    return null;
  }
  return (
    <section className={styles['feature']}>
      <h1 className={styles['subtitle']}>{t('infoFeatureBuyTitle')}</h1>
      <div className={styles['buy_feature']}>
        <img
          className={styles['buy_feature__img']}
          draggable="false"
          src={Resources.getSource(ResourceTypes.buyFeatureBtn)}
          alt="buyFeatureBtn"
        />
        <p className={styles['buy_feature__text']} dangerouslySetInnerHTML={{ __html: t('buyFeatureBtn') }} />
      </div>
      <div className={styles['row']}>
        <div className={styles['col']}>
          {infoFeatureBuy1.map((o) => (
            <p className={styles['p']} key={o.key} dangerouslySetInnerHTML={{ __html: o.text }} />
          ))}
        </div>
        <div className={styles['col']}>
          {infoFeatureBuy2.map((o) => (
            <p className={styles['p']} key={o.key} dangerouslySetInnerHTML={{ __html: o.text }} />
          ))}
        </div>
      </div>
    </section>
  );
};

const Features: React.VFC = () => {
  const { t } = useTranslation();
  const { isBuyFeatureEnabled } = useReactiveVar<ISlotConfig>(setSlotConfig);

  return (
    <div className={styles['gameRules']}>
      <h1 className={styles['title']}>{t('infoTabFeatures')}</h1>
      <CascadeFeature />
      {isBuyFeatureEnabled ? <FreeSpins /> : ''}
      <FreeSpinsFeature />
      <RageModeFeature />
      <RandomWildsFeature />
      <BuyFeatureFunction />
    </div>
  );
};

export default Features;
