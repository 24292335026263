import { useReactiveVar } from '@apollo/client';
import type React from 'react';
import { useTranslation } from 'react-i18next';

import { formatNumber } from '@phoenix7dev/utils-fe';

import { setSlotConfig, setUserBalance } from '../../gql/cache';
import type { ISlotConfig } from '../../gql/d';
import { getBetsSetting } from '../../gql/fromFragment';
import i18n from '../../i18next';

import styles from './info.module.scss';

const GameRules: React.FC = () => {
  const { t } = useTranslation();
  const userBalance = useReactiveVar(setUserBalance);
  const { isBuyFeatureEnabled } = useReactiveVar<ISlotConfig>(setSlotConfig);

  const betSettings = getBetsSetting();
  const { currency } = userBalance.balance;
  const minBet = formatNumber({ currency: currency, value: betSettings.minBetCount, showCurrency: false });
  const maxBet = formatNumber({ currency: currency, value: betSettings.maxBetCount, showCurrency: false });
  const gameRules1 = [
    { key: 'infoGameRules1_1', buyFeatureOnly: false, text: i18n.t('infoGameRules1_1') },
    { key: 'infoGameRules1_2', buyFeatureOnly: false, text: i18n.t('infoGameRules1_2') },
    { key: 'infoGameRules1_3', buyFeatureOnly: false, text: i18n.t('infoGameRules1_3') },
    { key: 'infoGameRules1_4', buyFeatureOnly: false, text: i18n.t('infoGameRules1_4') },
    { key: 'infoGameRules1_5', buyFeatureOnly: false, text: i18n.t('infoGameRules1_5') },
  ];
  const gameRules2 = [
    { key: 'infoGameRules1_6', buyFeatureOnly: false, text: i18n.t('infoGameRules1_6') },
    { key: 'infoGameRules1_7', buyFeatureOnly: false, text: i18n.t('infoGameRules1_7') },
    { key: 'infoGameRules1_8', buyFeatureOnly: false, text: i18n.t('infoGameRules1_8') },
    { key: 'infoGameRules1_9', buyFeatureOnly: false, text: i18n.t('infoGameRules1_9') },
    { key: 'infoGameRules1_10', buyFeatureOnly: false, text: i18n.t('infoGameRules1_10') },
  ];

  return (
    <div className={styles['gameRules']}>
      <h1 className={styles['title']}>{t('infoGameRulesTitle')}</h1>
      <div className={styles['row']}>
        <div className={styles['col']}>
          {gameRules1
            .filter((v) => isBuyFeatureEnabled || !v.buyFeatureOnly)
            .map((v) => (
              <p className={`${styles['p']}`} key={v.key} dangerouslySetInnerHTML={{ __html: v.text }} />
            ))}
        </div>
        <div className={styles['col']}>
          {gameRules2
            .filter((v) => isBuyFeatureEnabled || !v.buyFeatureOnly)
            .map((v) => (
              <p className={`${styles['p']}`} key={v.key} dangerouslySetInnerHTML={{ __html: v.text }} />
            ))}
        </div>
      </div>

      <p
        className={`${styles['p']} ${styles['center']}`}
        dangerouslySetInnerHTML={{
          __html: t('infoGameRules2', {
            minBet,
          }),
        }}
      />
      <p
        className={`${styles['p']} ${styles['center']}`}
        dangerouslySetInnerHTML={{
          __html: t('infoGameRules3', {
            maxBet,
          }),
        }}
      />

      <p
        className={`${styles['p']} ${styles['center']}`}
        dangerouslySetInnerHTML={{
          __html: t('infoGameRules4', {
            rtp: '96.38%',
          }),
        }}
      />
      {setSlotConfig().isBuyFeatureEnabled && (
        <>
          <p
            className={`${styles['p']} ${styles['center']}`}
            dangerouslySetInnerHTML={{
              __html: t('infoGameRules5', {
                rtp: '95.90%',
              }),
            }}
          />
          <p
            className={`${styles['p']} ${styles['center']}`}
            dangerouslySetInnerHTML={{
              __html: t('infoGameRules6', {
                rtp: '96.66%',
              }),
            }}
          />
        </>
      )}
      <p
        className={`${styles['p']} ${styles['center']}`}
        dangerouslySetInnerHTML={{
          __html: t('infoGameRules7', {
            version: window.__ENV__.APP_VERSION,
          }),
        }}
      />
    </div>
  );
};

export default GameRules;
