import _ from 'lodash';

import type { TAudioSprite } from '@phoenix7dev/audio-api/dist/d';

export enum ISongs {
  BGM_BG_Base_Loop = 'BGM_BG_Base_Loop',
  BGM_BG_Melo_Loop = 'BGM_BG_Melo_Loop',
  BGM_FS_Loop = 'BGM_FS_Loop',
  BGM_RM_Loop = 'BGM_RM_Loop',
  BigWin_End = 'BigWin_End',
  BigWin_Loop = 'BigWin_Loop',
  BuyButton = 'BuyButton',
  Cascade_Symbol_Explosion = 'Cascade_Symbol_Explosion',
  FS_EntrancePopup = 'FS_EntrancePopup',
  FS_ExitPopup = 'FS_ExitPopup',
  FeatureWilds = 'FeatureWilds',
  HighWin = 'HighWin',
  MediumWin = 'MediumWin',
  Multiplier_Popup = 'Multiplier_Popup',
  Progressbar_Fill = 'Progressbar_Fill',
  Progressbar_Off = 'Progressbar_Off',
  Progressbar_Win = 'Progressbar_Win',
  RM_EntrancePopup = 'RM_EntrancePopup',
  SFX_SM_CountUp_End = 'SFX_SM_CountUp_End',
  SFX_SM_CountUp_Loop = 'SFX_SM_CountUp_Loop',
  SFX_UI_AutoSpin = 'SFX_UI_AutoSpin',
  SFX_UI_BetChange = 'SFX_UI_BetChange',
  SFX_UI_Close = 'SFX_UI_Close',
  SFX_UI_General = 'SFX_UI_General',
  SFX_UI_Hover = 'SFX_UI_Hover',
  SFX_UI_MaxBet = 'SFX_UI_MaxBet',
  SFX_UI_MenuOpen = 'SFX_UI_MenuOpen',
  SFX_UI_SpinStart = 'SFX_UI_SpinStart',
  SFX_UI_SpinStop = 'SFX_UI_SpinStop',
  SFX_WIN_FeatureTrigger = 'SFX_WIN_FeatureTrigger',
  SFX_phoenix = 'SFX_phoenix',
  SmallWin = 'SmallWin',
  WildLanding = 'WildLanding',
  Win_Big = 'Win_Big',
  Win_Epic = 'Win_Epic',
  Win_Great = 'Win_Great',
  Win_Mega = 'Win_Mega',
}

export const audioSprite: TAudioSprite = {
  [ISongs.SFX_phoenix]: [0, 4033.333333333333],
  [ISongs.BGM_BG_Base_Loop]: [6000, 25500, true],
  [ISongs.BGM_BG_Melo_Loop]: [33000, 76000.04535147393, true],
  [ISongs.BGM_FS_Loop]: [111000, 76190.52154195012, true],
  [ISongs.BGM_RM_Loop]: [189000, 69818.231292517, true],
  [ISongs.BigWin_End]: [260000, 6687.755102040796],
  [ISongs.BigWin_Loop]: [268000, 27826.12244897962, true],
  [ISongs.BuyButton]: [297000, 464.7619047619287],
  [ISongs.Cascade_Symbol_Explosion]: [299000, 1429.1609977324242],
  [ISongs.FS_EntrancePopup]: [302000, 4178.594104308388],
  [ISongs.FS_ExitPopup]: [308000, 5338.45804988664],
  [ISongs.FeatureWilds]: [315000, 1807.1655328798215],
  [ISongs.HighWin]: [318000, 2643.809523809523],
  [ISongs.MediumWin]: [322000, 1957.7777777777783],
  [ISongs.Multiplier_Popup]: [325000, 1626.6666666666652],
  [ISongs.Progressbar_Fill]: [328000, 1089.3877551020523],
  [ISongs.Progressbar_Off]: [331000, 1224.5351473923165],
  [ISongs.Progressbar_Win]: [334000, 1540.6802721088297],
  [ISongs.RM_EntrancePopup]: [337000, 3784.535147392262],
  [ISongs.SFX_SM_CountUp_End]: [342000, 933.4693877551103],
  [ISongs.SFX_SM_CountUp_Loop]: [344000, 399.77324263037417, true],
  [ISongs.SFX_UI_AutoSpin]: [346000, 320.4988662131427],
  [ISongs.SFX_UI_BetChange]: [348000, 32.108843537400844],
  [ISongs.SFX_UI_Close]: [350000, 200.68027210885475],
  [ISongs.SFX_UI_General]: [352000, 88.34467120180989],
  [ISongs.SFX_UI_Hover]: [354000, 151.79138321997243],
  [ISongs.SFX_UI_MaxBet]: [356000, 122.94784580501528],
  [ISongs.SFX_UI_MenuOpen]: [358000, 277.1428571428487],
  [ISongs.SFX_UI_SpinStart]: [360000, 249.61451247168043],
  [ISongs.SFX_UI_SpinStop]: [362000, 151.02040816327644],
  [ISongs.SFX_WIN_FeatureTrigger]: [364000, 1033.3333333333599],
  [ISongs.SmallWin]: [367000, 1952.6077097505663],
  [ISongs.WildLanding]: [370000, 797.9591836734699],
  [ISongs.Win_Big]: [372000, 2400.0453514739206],
  [ISongs.Win_Epic]: [376000, 3900.0453514739206],
  [ISongs.Win_Great]: [381000, 4050.068027210898],
  [ISongs.Win_Mega]: [387000, 1750.0680272108866],
};

export const audioSpriteVolume: Record<ISongs, number> = {
  [ISongs.BGM_BG_Base_Loop]: 0.2,
  [ISongs.BGM_BG_Melo_Loop]: 0.2,
  [ISongs.BGM_FS_Loop]: 0.2,
  [ISongs.BGM_RM_Loop]: 0.2,
  [ISongs.BuyButton]: 0.2,
  [ISongs.Cascade_Symbol_Explosion]: 0.2,
  [ISongs.FS_EntrancePopup]: 0.2,
  [ISongs.BigWin_Loop]: 0.3,
  [ISongs.BigWin_End]: 0.3,
  [ISongs.FS_ExitPopup]: 0.3,
  [ISongs.FeatureWilds]: 0.3,
  [ISongs.HighWin]: 0.3,
  [ISongs.MediumWin]: 0.3,
  [ISongs.SmallWin]: 0.3,
  [ISongs.WildLanding]: 0.8,
  [ISongs.Multiplier_Popup]: 0.4,
  [ISongs.Progressbar_Fill]: 0.4,
  [ISongs.Progressbar_Off]: 0,
  [ISongs.Progressbar_Win]: 0.3,
  [ISongs.RM_EntrancePopup]: 0.3,
  [ISongs.Win_Big]: 0.3,
  [ISongs.Win_Mega]: 0.3,
  [ISongs.Win_Great]: 0.3,
  [ISongs.Win_Epic]: 0.4,
  [ISongs.SFX_SM_CountUp_End]: 0.3,
  [ISongs.SFX_SM_CountUp_Loop]: 0.6,
  [ISongs.SFX_UI_AutoSpin]: 0.4,
  [ISongs.SFX_UI_BetChange]: 0.4,
  [ISongs.SFX_UI_Close]: 0.4,
  [ISongs.SFX_UI_General]: 0.4,
  [ISongs.SFX_UI_Hover]: 0.4,
  [ISongs.SFX_UI_MaxBet]: 0.4,
  [ISongs.SFX_UI_MenuOpen]: 0.4,
  [ISongs.SFX_UI_SpinStart]: 0.2,
  [ISongs.SFX_UI_SpinStop]: 0.2,
  [ISongs.SFX_WIN_FeatureTrigger]: 0.3,
  [ISongs.SFX_phoenix]: 0.5,
};

export const audioSpriteGaps: { [key in ISongs]?: number } = {
  // [ISongs.Music_BigWin_End]: 2000,
};

export const mappedAudioSprites = _.reduce(
  audioSprite,
  (acc, value, key) => {
    _.set(acc, key, {
      start: value[0],
      duration: value[1] - (audioSpriteGaps[key as ISongs] || 0),
      loop: !!value[2],
    });
    return acc;
  },
  {},
) as {
  [key in ISongs]: { start: number; duration: number; loop: boolean };
};
