import { Sprite, Texture } from 'pixi.js';

import { EventTypes } from '../../global.d';
import type Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { ViewContainer } from '../components/ViewContainer';
import { FREE_SPINS_FADE_DURATION, eventManager } from '../config';

class FadeArea extends ViewContainer {
  private sprite: Sprite;

  constructor() {
    super();
    this.sprite = new Sprite(Texture.WHITE);
    this.sprite.width = 100;
    this.sprite.height = 100;
    this.sprite.tint = 0x000000;
    this.alpha = 0;
    this.zIndex = 3;
    this.addChild(this.sprite);
    eventManager.addListener(EventTypes.START_MODE_CHANGE_FADE, this.startModeChangeFade.bind(this));
  }

  protected override resize(width: number, height: number): void {
    this.sprite.width = width;
    this.sprite.height = height;
  }

  private startModeChangeFade(callback: () => void): void {
    const chain = new AnimationChain();
    const fadeOut = this.getFadeAnimation(1, FREE_SPINS_FADE_DURATION / 2);
    const fadeIn = this.getFadeAnimation(0, FREE_SPINS_FADE_DURATION / 2);
    fadeOut.addOnComplete(callback);
    chain.appendAnimation(fadeOut);
    chain.appendAnimation(fadeIn);
    chain.addOnStart(() => {
      this.interactive = true;
    });
    chain.addOnComplete(() => {
      this.interactive = false;
    });
    chain.start();
  }

  private getFadeAnimation(alpha: number, duration: number): Animation {
    const animation = new Tween({
      object: this,
      duration,
      propertyBeginValue: alpha === 1 ? 0 : 1,
      target: alpha,
      property: TweenProperties.ALPHA,
    });
    return animation;
  }
}

export default FadeArea;
