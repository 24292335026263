import i18n from 'i18next';
import { ITextStyle, Sprite, Texture } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, FeatureState } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { getBonusIdByFeature } from '../../utils';
import { titleBaseTextStyle, totalCostTextStyle, totalCostValueTextStyle } from '../buyFeature/textStyles';
import { SpriteButton, SpriteButtonState } from '../components/SpriteButton';
import { TextField } from '../components/TextField';
import { PopupTypes, eventManager } from '../config';
import type { BuyFeatureConfirmProps } from '../d';

import { PopupController } from './PopupController';
import { Popup } from './popup';

class BuyFeaturePopupConfirm extends Popup {
  private popupBg: Sprite = new Sprite(Texture.WHITE);

  private coinAmount: number;

  private featureState: FeatureState = FeatureState.BASE;

  private titleText: TextField;

  private totalCostText: TextField;

  private totalCostValue: TextField;

  private confirmBtn: Sprite;

  private cancelBtn: Sprite;

  constructor() {
    super();
    this.x = 0;
    this.y = 0;
    this.visible = false;
    this.coinAmount = 1;
    this.initPopupBg();
    this.titleText = this.initTitle();
    this.totalCostText = this.initTotalCostText();
    this.totalCostValue = this.initTotalCostValue();
    this.cancelBtn = this.initCancelBtn();
    this.confirmBtn = this.initConfirmBtn();
    this.init();
  }

  public override show(props: BuyFeatureConfirmProps): void {
    super.show();
    const { featureState, coinAmount, totalCost } = props;
    this.popupBg.texture = Texture.from(
      featureState === FeatureState.BASE ? ResourceTypes.buyFeaturePopupConfirm : ResourceTypes.buyFeaturePopup,
    );
    this.titleText.setText(
      i18n.t<string>(featureState === FeatureState.BASE ? 'buyFeatureConfirmTitleBase' : 'buyFeatureConfirmTitleRage'),
    );
    this.featureState = featureState;
    this.visible = true;
    this.coinAmount = coinAmount;
    this.totalCostValue.text.text = totalCost;
    this.confirmBtn.interactive = true;
    this.cancelBtn.interactive = true;
  }

  public override hide(): void {
    super.hide();
    this.visible = false;
  }

  private init(): void {
    this.addChild(this.popupBg);
    this.addChild(this.titleText.getText());
    this.addChild(this.totalCostText.getText());
    this.addChild(this.totalCostValue.getText());
    this.addChild(this.confirmBtn);
    this.addChild(this.cancelBtn);
  }

  private initPopupBg(): void {
    this.popupBg.name = 'this.popupBg';
    this.popupBg.x = 0;
    this.popupBg.y = 0;
    this.popupBg.anchor.set(0.5);
  }

  private initTitle(): TextField {
    const title = new TextField(
      i18n.t<string>('buyFeatureConfirmTitleBase'),
      400,
      150,
      titleBaseTextStyle as Partial<ITextStyle>,
    );
    title.text.y = -65;
    title.text.x = 0;
    title.text.anchor.set(0.5, 0.5);
    return title;
  }

  private initTotalCostText(): TextField {
    const totalCostText = new TextField(
      i18n.t<string>('buyFeatureTotalCost'),
      400,
      100,
      totalCostTextStyle as Partial<ITextStyle>,
    );
    totalCostText.text.name = 'totalCostText';
    totalCostText.text.y = 60;
    totalCostText.text.x = 0;
    totalCostText.text.anchor.set(0.5);

    return totalCostText;
  }

  private initTotalCostValue(): TextField {
    const betValue = new TextField('0', 300, 100, totalCostValueTextStyle as Partial<ITextStyle>);
    betValue.text.name = 'totalCostValue';
    betValue.text.y = 80;
    betValue.text.x = 0;
    betValue.text.anchor.set(0.5, 0);

    return betValue;
  }

  private initCancelBtn(): SpriteButton {
    const cancelBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.buyFeatureCancelBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.buyFeatureCancelBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.buyFeatureCancelBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.buyFeatureCancelBtn),
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: () => {
        this.handleClose();
        AudioApi.play({ type: ISongs.SFX_UI_Close });
      },
      onTouchStart: () => {
        this.handleClose();
        AudioApi.play({ type: ISongs.SFX_UI_Close });
      },
    });
    cancelBtn.y = 205;
    cancelBtn.x = -42;
    cancelBtn.anchor.set(1, 0.5);
    return cancelBtn;
  }

  private initConfirmBtn(): Sprite {
    const confirmBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.buyFeatureConfirmBtnDefault),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.buyFeatureConfirmBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.buyFeatureConfirmBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.buyFeatureConfirmBtnDisabled),
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: () => {
        AudioApi.play({ type: ISongs.BuyButton });
        this.startBuyFeature();
      },
      onTouchStart: () => {
        AudioApi.play({ type: ISongs.BuyButton });
        this.startBuyFeature();
      },
    });

    confirmBtn.name = 'confirmBtn';
    confirmBtn.y = 205;
    confirmBtn.x = 42;
    confirmBtn.anchor.set(0, 0.5);
    return confirmBtn;
  }

  private startBuyFeature(): void {
    this.confirmBtn.interactive = false;
    this.cancelBtn.interactive = false;
    eventManager.emit(EventTypes.HANDLE_BUY_BONUS, getBonusIdByFeature(this.featureState));
  }

  public handleClose(_closeBuyFeature?: boolean): void {
    this.confirmBtn.interactive = false;
    this.cancelBtn.interactive = false;
    PopupController.the.openPopup(PopupTypes.BUY_FEATURE);
  }

  protected override resize(_width: number, _height: number): void {
    const isLandscape = _width >= _height;

    if (isLandscape && _width < 916) {
      this.scale.set(1.7);
    } else if (!isLandscape && _width < 768) {
      this.scale.set(2);
    } else {
      this.scale.set(1);
    }
  }
}

export default BuyFeaturePopupConfirm;
