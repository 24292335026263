import i18n from 'i18next';
import { Spine } from 'pixi-spine';
import { ITextStyle, Loader, Sprite, Texture } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, Variables, mappedAudioSprites } from '../../config';
import { EventTypes } from '../../global.d';
import { setCurrentBonus, setIsContinueAutoSpinsAfterFeature, setIsDuringBigWinLoop } from '../../gql/cache';
import { delayedAction } from '../../utils';
import type Animation from '../animations/animation';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { TextField } from '../components/TextField';
import { eventManager } from '../config';
import type { PopupProps } from '../d';

import { Popup } from './popup';

const textStyle: Partial<ITextStyle> = {
  align: 'center',
  fill: ['#fff', '#FFEE57'],
  fillGradientStops: [0, 1],
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 90,
  fontWeight: 'bold',
  miterLimit: 48,
  stroke: '#4A00A9',
  strokeThickness: 18,
  whiteSpace: 'normal',
  wordWrapWidth: 140,
  lineJoin: 'round',
};
const text2Style: Partial<ITextStyle> = {
  align: 'center',
  dropShadowAngle: -1.2,
  dropShadowBlur: 9,
  dropShadowColor: '#fff700',
  dropShadowDistance: 0,
  fill: 'white',
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 50,
  miterLimit: 48,
  stroke: '#000000',
  strokeThickness: 10,
  whiteSpace: 'normal',
  wordWrapWidth: 140,
  lineJoin: 'round',
};
export class FreeSpinsPopup extends Popup {
  protected background: Sprite;

  protected spine: Spine;

  protected text: TextField;

  protected pressToContinueText: TextField;

  protected backgroundFadeInAnimation: Animation;

  protected backgroundFadeOutAnimation: Animation;

  protected autoSkipDelay: ReturnType<typeof setTimeout> | undefined;

  private spineListener = {
    start: (entry: { trackIndex: number }) => {
      if (entry.trackIndex !== 2) return;
      this.backgroundFadeOutAnimation.start();
    },
    complete: (entry: { trackIndex: number }) => {
      if (entry.trackIndex !== 2) return;
      this.visible = false;
      eventManager.emit(EventTypes.START_FREE_SPINS);
      this.spine.state.removeListener(this.spineListener);
      this.spine.state.addEmptyAnimation(2, 0, 0);
    },
  };

  private bindedCallback = () => {
    clearTimeout(this.autoSkipDelay);
    eventManager.removeListener(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.bindedCallback);
    this.removeListener('click', this.bindedCallback);
    this.removeListener('touchstart', this.bindedCallback);
    this.spine.state.setAnimation(2, 'free_spins_pop_up_out', false);
    this.spine.state.addListener(this.spineListener);
    this.spine.state.addEmptyAnimation(0, 0, 0);
  };

  constructor() {
    super();
    this.interactive = true;
    this.visible = false;
    this.text = new TextField('', 500, 100, textStyle);
    this.text.text.anchor.set(0.5, 0.5);
    this.background = this.initBackground();
    this.spine = this.initSpine();
    this.backgroundFadeInAnimation = this.initBackGroundFadeInAnimation();
    this.backgroundFadeOutAnimation = this.initBackGroundFadeOutAnimation();
    this.pressToContinueText = this.initPressToContinueText();
    this.init();
  }

  protected init(): void {
    this.addChild(this.background);
    this.addChild(this.spine);

    const uWonPlaceholder = this.spine.skeleton.findSlot('you_won_place_holder').currentSprite as Sprite;
    uWonPlaceholder.texture = Texture.EMPTY;

    const numbersPlaceholder = this.spine.skeleton.findSlot('numbers_place_holder').currentSprite as Sprite;
    numbersPlaceholder.texture = Texture.EMPTY;
    numbersPlaceholder.addChild(this.text.getText());

    const pressToContinuePlaceholder = this.spine.skeleton.findSlot('in_10_fs_place_holder').currentSprite as Sprite;
    pressToContinuePlaceholder.texture = Texture.EMPTY;

    this.spine.addChild(this.pressToContinueText.getText());
  }

  private initBackground(): Sprite {
    const sprite = new Sprite(Texture.WHITE);
    sprite.tint = 0x000000;
    sprite.anchor.set(0.5, 0.5);
    return sprite;
  }

  private initPressToContinueText(): TextField {
    const text = new TextField(i18n.t('freeSpinsPressToContinue'), 500, 100, text2Style);
    text.text.anchor.set(0.5, 0.5);
    text.text.position.set(0, 350);
    return text;
  }

  private initSpine(): Spine {
    const spine = new Spine(Loader.shared.resources['popups']!.spineData!);

    return spine;
  }

  protected override resize(_width: number, _height: number): void {
    this.background.width = _width;
    this.background.height = _height;
    this.spine.y = 0;
    this.position.set(_width / 2, _height / 2);

    const isLandscape = _width >= _height;
    if (isLandscape) {
      const factor = Math.min(_height / 1080, _width / 1920);
      this.spine.scale.set(factor);
    } else {
      const factor = Math.min(_height / 1920, _width / 1080);
      this.spine.scale.set(factor);
    }
  }

  private initBackGroundFadeInAnimation(): Animation {
    return new Tween({
      object: this.background,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 0,
      target: 0.7,
      duration: 500,
    });
  }

  private initBackGroundFadeOutAnimation(): Animation {
    return new Tween({
      object: this.background,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 0.7,
      target: 0,
      duration: 500,
    });
  }

  public override show(_props?: PopupProps): void {
    AudioApi.play({ type: ISongs.FS_EntrancePopup });
    delayedAction(mappedAudioSprites[ISongs.FS_EntrancePopup].duration, () => {
      AudioApi.play({ type: ISongs.BGM_FS_Loop });
      if (setIsDuringBigWinLoop()) {
        AudioApi.fadeOut(0, ISongs.BGM_FS_Loop);
      }
    });
    this.on('click', this.bindedCallback);
    this.on('touchstart', this.bindedCallback);
    this.text.setText(`${setCurrentBonus().rounds} ${i18n.t('freeSpinsTitle')}`);
    eventManager.once(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.bindedCallback);
    if (setIsContinueAutoSpinsAfterFeature()) {
      this.autoSkipDelay = setTimeout(this.bindedCallback, 5000);
    }
    this.visible = true;
    this.spine.state.addListener({
      start: (entry) => {
        if (entry.trackIndex === 0) {
          this.backgroundFadeInAnimation.start();
          this.spine.state.setAnimation(1, 'free_spins_pop_up_loop', true);
        }
      },
    });
    this.spine.state.setAnimation(0, 'free_spins_pop_up_in', false);
  }
}
