import { Spine } from 'pixi-spine';
import { Loader, Sprite, Texture } from 'pixi.js';

import { EventTypes, GameMode, UserBonus } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { ViewContainer } from '../components/ViewContainer';
import { eventManager } from '../config';

class Background extends ViewContainer {
  private isLandscape = true;

  private baseBg = Texture.from(ResourceTypes.slotBg);

  private freeSpinsBg = Texture.from(ResourceTypes.slotBgFreeSpins);

  private rageModeBg = Texture.from(ResourceTypes.slotBgPartyMode);

  private backgroundSpine = new Spine(Loader.shared.resources['background']!.spineData!);

  private bgSprite = new Sprite();

  constructor() {
    super();

    this.bgSprite.texture = this.baseBg;
    this.bgSprite.anchor.set(0.5);
    this.addChild(this.bgSprite);
    this.addChild(this.backgroundSpine);
    this.backgroundSpine.state.setAnimation(0, 'bg', true);

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
  }

  protected override onBrokenGame(bonus: UserBonus): void {
    switch (bonus.gameMode) {
      case GameMode.FREE_SPINS:
        this.handleFreeSpinsChanges();
        break;
      case GameMode.RAGE_MODE:
        this.handleRageModeChanges();
        break;
      default:
        break;
    }
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.handleBaseModeChanges();
        break;
      case GameMode.FREE_SPINS:
        this.handleFreeSpinsChanges();
        break;
      case GameMode.RAGE_MODE:
        this.handleRageModeChanges();
        break;
      default:
        this.handleBaseModeChanges();
    }
  }

  private handleBaseModeChanges(): void {
    this.bgSprite.texture = this.baseBg;
    const name = 'bg';
    this.backgroundSpine.state.setAnimation(0, name, true);
  }

  private handleFreeSpinsChanges(): void {
    this.bgSprite.texture = this.freeSpinsBg;
    const name = 'bg_fs';
    this.backgroundSpine.state.setAnimation(0, name, true);
  }

  private handleRageModeChanges(): void {
    this.bgSprite.texture = this.rageModeBg;
    const name = 'bg_rm';
    this.backgroundSpine.state.setAnimation(0, name, true);
  }

  protected override resize(width: number, height: number): void {
    this.isLandscape = width >= height;
    this.x = width / 2;
    this.y = height / 2;

    const bgAspectRatio = this.bgSprite.width / this.bgSprite.height;
    const aspectRatio = width / height;

    if (bgAspectRatio > aspectRatio) {
      this.scale.set(height / this.bgSprite.height);
    } else {
      this.scale.set(width / this.bgSprite.width);
    }
  }
}
export default Background;
