import { Spine } from 'pixi-spine';
import { Container, Loader } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, MAPPED_SYMBOLS_WIN_ANIMATIONS, SlotId } from '../../config';
import { Cascade, EventTypes } from '../../global.d';
import { setIsTurboSpin } from '../../gql/cache';
import { delayedAction, nextTick } from '../../utils';
import type Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import Tween from '../animations/tween';
import { ViewContainer } from '../components/ViewContainer';
import { REELS_AMOUNT, REEL_WIDTH, SLOTS_PER_REEL_AMOUNT, SLOT_HEIGHT, SLOT_SCALE, eventManager } from '../config';
import type { Icon } from '../d';

class WinSlotsContainer extends ViewContainer {
  public animation: Animation | null = null;

  public multiplierSpine: Spine;

  private winFramesContainer = new Container();

  private symbolAnimationsContainer = new Container();

  constructor() {
    super();
    this.multiplierSpine = new Spine(Loader.shared.resources['multipliers']!.spineData!);
    this.multiplierSpine.x = (REEL_WIDTH * REELS_AMOUNT) / 2;
    this.multiplierSpine.y = (SLOT_HEIGHT * SLOTS_PER_REEL_AMOUNT) / 2;
    this.multiplierSpine.state.addListener({
      start: () => {
        this.multiplierSpine.visible = true;
      },
      complete: () => {
        this.multiplierSpine.visible = false;
      },
    });
    this.addChild(this.multiplierSpine);
    this.addChild(this.winFramesContainer);
    this.addChild(this.symbolAnimationsContainer);
    eventManager.addListener(EventTypes.START_MULTIPLIER_ANIMATION, this.showMultiplierAnimation.bind(this));
    eventManager.addListener(EventTypes.START_WIN_ANIMATION, this.showWin.bind(this));
  }

  private showMultiplierAnimation(animationName: string): void {
    AudioApi.play({ type: ISongs.Multiplier_Popup });
    this.multiplierSpine.state.setAnimation(0, animationName, false);
  }

  private createSlotSpineAnimation(id: number, _slotId: SlotId, srcName: string, animationName: string): Animation {
    const timeScale = setIsTurboSpin() ? 1.5 : 1;
    const dummy = Tween.createDelayAnimation(1200 / timeScale);
    const animation = new Spine(Loader.shared.resources[srcName as string]!.spineData!);
    const explosion = new Spine(Loader.shared.resources['explosion']!.spineData!);
    const winFrame = new Spine(Loader.shared.resources['winFrame']!.spineData!);

    explosion.state.timeScale = timeScale;
    animation.state.timeScale = timeScale;
    winFrame.state.timeScale = timeScale;

    dummy.addOnStart(() => {
      const x = REEL_WIDTH / 2 + REEL_WIDTH * (id % REELS_AMOUNT);
      const y = SLOT_HEIGHT * Math.floor(id / REELS_AMOUNT) + SLOT_HEIGHT / 2;

      animation.position.set(x, y);
      explosion.position.set(x, y);
      winFrame.position.set(x, y);
      animation.scale.set(SLOT_SCALE);
      winFrame.scale.set(SLOT_SCALE);

      animation.state.addListener({
        complete: (_entry) => {
          nextTick(() => {
            animation.destroy();
          });
        },
      });
      winFrame.state.addListener({
        complete: (_entry) => {
          nextTick(() => {
            winFrame.destroy();
          });
        },
      });
      this.winFramesContainer.addChild(winFrame);
      this.symbolAnimationsContainer.addChild(animation, explosion);
      animation.state.setAnimation(0, animationName, false);
      winFrame.state.setAnimation(0, 'win_frame', false);
      delayedAction(800 / timeScale, () => {
        explosion.state.addListener({
          complete: (_entry) => {
            nextTick(() => explosion.destroy());
          },
        });
        explosion.state.setAnimation(0, 'explosion', false);
      });
    });
    return dummy;
  }

  private showWin(spinResult: Icon[], cascade: Cascade, id: number): void {
    const currentSpinResult = [...spinResult];
    this.animation = this.createCascadeAnimation(currentSpinResult, cascade, id);
    this.animation.addOnComplete(() => {
      eventManager.emit(EventTypes.NEXT_CASCADE, id + 1);
    });
    this.animation.start();
  }

  private createCascadeAnimation(spinResult: Icon[], cascades: Cascade, _id: number): Animation {
    const chain = new AnimationChain();
    const animationGroup = new AnimationGroup();
    const paylines = cascades.winPositions
      .reduce((res, current) => {
        return [...res, ...current];
      }, [])
      .filter((v, i, a) => a.indexOf(v) === i);
    paylines.forEach((winId) => {
      animationGroup.addAnimation(
        this.createSlotSpineAnimation(
          winId,
          (spinResult[winId as number] as Icon).id,
          MAPPED_SYMBOLS_WIN_ANIMATIONS[(spinResult[winId as number] as Icon).id].src!,
          MAPPED_SYMBOLS_WIN_ANIMATIONS[(spinResult[winId as number] as Icon).id].animation!,
        ),
      );
    });
    const timeScale = setIsTurboSpin() ? 1.5 : 1;
    const audioDelay = Tween.createDelayAnimation(1000 / timeScale);
    audioDelay.addOnComplete(() => AudioApi.play({ type: ISongs.Cascade_Symbol_Explosion, stopPrev: true }));
    animationGroup.addAnimation(audioDelay);
    animationGroup.addOnStart(() => {
      eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, paylines, false);
    });
    chain.appendAnimation(animationGroup);
    return chain;
  }
}

export default WinSlotsContainer;
