import { Sprite, Texture } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, GameMode, PopupOpeningTypes } from '../../global.d';
import { setCurrentBonus, setIsAutoSpins, setIsPopupOpeningInProgress } from '../../gql/cache';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { isMobileLandscape, isMobilePortrait, isTabletLandscape, isTabletPortrait } from '../../utils';
import { TextField } from '../components/TextField';
import { ViewContainer } from '../components/ViewContainer';
import {
  PopupTypes,
  buyFeatureClickTextStyle,
  buyFeatureDisableTextStyle,
  buyFeatureHoverTextStyle,
  buyFeatureTextStyle,
  eventManager,
} from '../config';
import { PopupController } from '../popups/PopupController';

class BuyFeatureBtn extends ViewContainer {
  private btn: Sprite;

  private text: TextField;

  isDisabled: boolean;

  constructor() {
    super();
    this.x = -750;
    this.y = 0;
    this.isDisabled = false;
    this.text = this.initBuyFeatureText();
    this.btn = this.initBuyFeatureBtn();
    this.addChild(this.btn);

    eventManager.on(EventTypes.DISABLE_BUY_FEATURE_BTN, (disable: boolean) => {
      this.handleDisable(disable);
    });
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.visible = true;
        break;
      case GameMode.FREE_SPINS:
        this.visible = false;
        break;
      case GameMode.RAGE_MODE:
        this.visible = false;
        break;
      default:
        this.visible = true;
        break;
    }
  }

  private initBuyFeatureBtn() {
    const btn = new Sprite(Texture.from(ResourceTypes.buyFeatureBtn));
    btn.anchor.set(0.5);
    btn.interactive = true;
    btn.buttonMode = true;
    btn.addChild(this.text.getText());
    btn.on('click', () => this.onClick());
    btn.on('touchstart', () => this.onClick());

    btn.addListener('mouseover', (_e) => {
      if (!this.isDisabled) {
        AudioApi.play({ type: ISongs.SFX_UI_Hover });
        this.btn.texture = Texture.from(ResourceTypes.buyFeatureBtnHover);
        this.text.text.style = buyFeatureHoverTextStyle;
      }
    });
    btn.addListener('mouseout', () => {
      if (!this.isDisabled) {
        this.btn.texture = Texture.from(ResourceTypes.buyFeatureBtn);
        this.text.text.style = buyFeatureTextStyle;
      }
    });
    btn.addListener('mousedown', () => {
      if (!this.isDisabled) {
        this.btn.texture = Texture.from(ResourceTypes.buyFeatureBtnPressed);

        this.text.text.style = buyFeatureClickTextStyle;
      }
    });
    btn.addListener('mouseup', () => {
      if (!this.isDisabled) {
        this.btn.texture = Texture.from(ResourceTypes.buyFeatureBtn);
        this.text.text.style = buyFeatureTextStyle;
      }
    });
    return btn;
  }

  private initBuyFeatureText(): TextField {
    const text = new TextField(i18n.t<string>('buyFeatureBtn'), 110, 300, buyFeatureTextStyle);

    text.text.anchor.set(0.5, 0.5);
    text.text.position.set(this.width / 2, this.height / 2);

    return text;
  }

  private onClick(): void {
    if (!this.isDisabled && setIsPopupOpeningInProgress() === PopupOpeningTypes.NONE && !setIsAutoSpins()) {
      setIsPopupOpeningInProgress(PopupOpeningTypes.BUY_FEATURE_POPUP);
      eventManager.emit(EventTypes.DISABLE_PAYTABLE);
      AudioApi.play({ type: ISongs.BuyButton });
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
      PopupController.the.openPopup(PopupTypes.BUY_FEATURE);

      setTimeout(() => setIsPopupOpeningInProgress(PopupOpeningTypes.NONE), 100);
    }
  }

  private handleDisable(disable: boolean): void {
    if (this.isAutoSpinInProgress || this.isFreeRoundBonus) return;

    this.isDisabled = disable;
    if (disable) {
      this.btn.buttonMode = false;
      this.btn.texture = Texture.from(ResourceTypes.buyFeatureBtnDisable);
      this.text.text.style = buyFeatureDisableTextStyle;
      this.text.text.alpha = 0.5;
    } else {
      this.btn.buttonMode = true;
      this.btn.texture = Texture.from(ResourceTypes.buyFeatureBtn);
      this.text.text.style = buyFeatureTextStyle;
      this.text.text.alpha = 1;
    }
  }

  private get isAutoSpinInProgress(): boolean {
    return this.isDisabled && setIsAutoSpins();
  }

  private get isFreeRoundBonus(): boolean {
    return this.isDisabled && setCurrentBonus().gameMode === GameMode.FREE_ROUND_BONUS && setCurrentBonus().isActive;
  }

  protected override resize(_width: number, _height: number): void {
    this.scale.set(1.3);
    this.y = 0;
    this.x = -775;

    if (isTabletLandscape(_width, _height)) {
      this.scale.set(1.5);
      this.x = -835;
      this.y = 0;
    }
    if (isTabletPortrait(_width, _height)) {
      this.scale.set(1.5);
      this.y = 620;
      this.x = 590;
    }
    if (isMobilePortrait(_width, _height)) {
      this.scale.set(1.8);
      this.y = 650;
      this.x = 480;
    }
    if (isMobileLandscape(_width, _height)) {
      this.scale.set(1.5);
      this.x = -900;
    }
  }
}

export default BuyFeatureBtn;
