import i18n from 'i18next';
import { Sprite, Texture } from 'pixi.js';

import { Variables } from '../../config';
import { EventTypes, GameMode } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { TextField } from '../components/TextField';
import { ViewContainer } from '../components/ViewContainer';
import { eventManager } from '../config';

const freeSpinsLeftTextStyle = {
  default: {
    align: 'center',
    fill: '#FFCC48',
    fillGradientStops: [0.6],
    fontFamily: Variables.FONT_FAMILY,
    fontSize: 90,
    fontWeight: '700',
    lineJoin: 'round',
    miterLimit: 0,
    stroke: '#3F0071',
    strokeThickness: 20,
    whiteSpace: 'normal',
  },
  color: {
    fill: '#ffffff',
  },
};
export class FreeSpinsLeft extends ViewContainer {
  private background: Sprite;

  private spins: TextField;

  constructor() {
    super();
    this.x = 0;
    this.y = 415;
    this.visible = false;
    this.background = this.initBackground();
    this.spins = this.initSpins();
    this.addChild(this.background, this.spins.getText());
    eventManager.addListener(EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE, this.updateSpins.bind(this));
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.visible = false;
        break;
      case GameMode.FREE_SPINS:
        this.visible = true;
        break;
      case GameMode.RAGE_MODE:
        this.visible = true;
        break;
      default:
        this.visible = false;
        break;
    }
  }

  private updateSpins(current: number, total: number): void {
    this.spins.setText(`${i18n.t('freeSpinsTitle')} <color>${current}/${total}</color>`);
  }

  private initBackground(): Sprite {
    const background = new Sprite(Texture.from(ResourceTypes.freeSpinsCounter));
    background.anchor.set(0.5);
    return background;
  }

  private initSpins(): TextField {
    const text = new TextField('', 500, 70, {}, true, freeSpinsLeftTextStyle);
    text.text.anchor.set(0.5);
    return text;
  }
}
